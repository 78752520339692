/* stylelint-disable no-duplicate-selectors */
// ********** FOOTER ********** //

// *** Main Layout *** //

footer.footer {
  position: relative;
  padding: 0;
  font-size: 1em;
  overflow: hidden;

  // *** Image in background *** //
  &::after {
    z-index: 0;
    isolation: isolate;
    content: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-foot-fade.svg");
    width: 780px;
    position: absolute;
    bottom: -220px;
    left: -140px;
    opacity: 0.03;

    @media only screen and (max-width: 991px) {
      bottom: -170px;
    }

    @media only screen and (max-width: 481px) {
      left: -250px;
    }
  }

  // *** Image in background End *** //

  @at-root .footersection-1 {
    position: relative;
    z-index: 1;
    isolation: isolate;
    @include paddings;

    > .row {
      max-width: 1380px;
      margin-inline: auto;
      gap: 20px;
      @include flex($vert: "top");

      > div {
        width: auto;

        .logo {
          border-bottom: hidden;
          margin-block: 17px 0;
          padding-bottom: 0;
          width: 170px;
        }
      }

      &::before,
      &::after {
        display: none;
      }

      @media (max-width: 991px) {
        flex-direction: column;
      }
    }
  }
}

// *** End Main Layout *** //

// *** Dealer Details *** //

footer.footer {
  .dealerdetails_container {
    .info {
      padding: 0;
    }

    .logo {
      border: 0;
      padding: 0;
      margin: 0 0 30px;

      img {
        max-width: 300px !important;
      }
    }
  }
}

// *** End Dealer Details *** //

// *** Footer Links *** //

footer.footer {
  .quicklinks {
    .title {
      font-size: 1em;
      text-transform: none;
      margin: 0 0 40px;

      &::before {
        content: "";
        height: 1px;
        width: 60px;
        background-color: $col-primary;
        position: absolute;
        top: 37px;
      }

      &::after {
        content: "";
        height: 7px;
        width: 7px;
        border-radius: 50%;
        background-color: $col-primary;
        position: absolute;
        top: 34px;
        left: 75px;
      }
    }

    ul {
      font-size: 1em;

      li {
        font-size: 0.875em;
        margin: 0 0 22px;

        &:last-child {
          margin: 0;
        }

        a {
          width: 100%;
          @include flex;

          &:focus-within {
            color: #fff;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .contacts {
    h4 {
      position: relative;
      font-size: 1em;
      text-transform: none;
      margin: 0 0 40px;

      &::before {
        content: "";
        height: 1px;
        width: 60px;
        background-color: $col-primary;
        position: absolute;
        top: 37px;
      }

      &::after {
        content: "";
        height: 7px;
        width: 7px;
        border-radius: 50%;
        background-color: $col-primary;
        position: absolute;
        top: 34px;
        left: 60px;
      }
    }

    .contact-block {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 15px;
      font-size: 0.875em;

      a:focus-within {
        color: #fff;
        text-decoration: underline;
      }

      .contact-icon {
        width: 20px;
        margin-right: 0;
        display: inline;

        i {
          filter: $filter-primary;
        }
      }
    }

    .opening-hours {
      margin-top: 5px;

      .hours-icon {
        background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-clock.svg");
        filter: $filter-primary;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin-bottom: 5px;
      }

      .hours-description {
        display: block;
        line-height: 1.7;
      }
    }
  }
}

// * Accredited Badges *//
.footer-badges {
  flex-direction: column;
  justify-content: center;
  gap: 14px;
  padding-top: 17px;

  img {
    height: 76px;
  }
}

// * Accredited Badges End *//

// * Footer Accordion Styles * //

@media only screen and (max-width: 991px) {
  footer.footer {
    .foot-menu {
      padding-top: 0;

      .title {
        cursor: pointer;
        margin: 0 !important;
        background: $col-primary;
        padding: 20px;
        color: #fff;

        &::after {
          content: "+";
          position: relative;
          top: 0;
          left: 10px;
        }
      }

      ul {
        max-height: 0;
        overflow: hidden;
        transition: max-height 1s ease-out;
        padding: 0;
        margin: 0;

        li {
          margin: 0;
          padding: 0;
          border: 0;
          display: block;

          a {
            display: block;
            margin: 10px 0;
            padding: 20px;
            border: 1px solid #fff;
            font-size: 16px;
            background: none;
            @include flex;
          }
        }
      }

      &.open {
        .title::after {
          content: "-";
        }

        ul {
          height: auto;
          max-height: 500px;
          transition: max-height 1s ease-in-out !important;
        }
      }
    }

    .row {
      .footer-badges {
        gap: 10px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  footer.footer {
    .row {
      align-items: center;

      .menulinks_container {
        width: 100%;
      }

      .contacts {
        display: flex;
        flex-direction: column;
        align-items: center;

        h4 {
          width: max-content;

          &::after {
            float: right;
          }
        }

        .contact-block {
          display: flex;
          flex-direction: column;
          gap: 0;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          text-align: center;
        }
      }
    }
  }
}

// * End Footer Accordion Styles * //

// *** End Footer Links *** //

// *** Footer Bot *** //

.copyright_container {
  position: relative;
  z-index: 1;
  isolation: isolate;
  background-color: $col-primary;
  padding-block: 10px;

  > div {
    @include container;
  }

  p,
  a {
    color: $col-text;
  }
}

// *** End Footer Bot *** //

// ********** END FOOTER ********** //
