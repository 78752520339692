// ********** MIXINS CSS ********** //

// *** Flex ***/
@mixin flex($horiz: "space-between", $vert: "center") {
  display: flex;

  @if $horiz == "space-between" {
    justify-content: space-between;
  }

  @if $horiz == "center" {
    justify-content: center;
  }

  @if $horiz == "space-around" {
    justify-content: space-around;
  }

  @if $horiz == "flex-start" {
    justify-content: flex-start;
  }

  @if $horiz == "flex-end" {
    justify-content: flex-end;
  }

  @if $vert == "center" {
    align-items: center;
  }

  @if $vert == "flex-start" {
    align-items: flex-start;
  }

  @if $vert == "flex-end" {
    align-items: flex-end;
  }

  @if $vert == "stretch" {
    align-items: stretch;
  }
}

// *** container *** //
@mixin container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;

  @media only screen and (min-width: 992px) {
    width: 970px;
  }

  @media only screen and (min-width: 1200px) {
    width: 1180px;
  }

  @media only screen and (min-width: 1470px) {
    width: 1450px;
  }
}

// *** Theme Paddings *** //
@mixin paddings($size: $spacing, $type: "vert") {
  $s: $size;

  @if $size == "half" {
    $s: calc(#{$spacing} / 2);
  }

  @if $type == "vert" {
    padding-top: $s;
    padding-bottom: $s;

    @media (max-width: 768px) {
      padding-top: calc(#{$s} / 2);
      padding-bottom: calc(#{$s} / 2);
    }
  }

  @if $type == "top" {
    padding-top: $s;

    @media (max-width: 768px) {
      padding-top: calc(#{$s} / 2);
    }
  }

  @if $type == "bot" {
    padding-bottom: $s;

    @media (max-width: 768px) {
      padding-bottom: calc(#{$s} / 2);
    }
  }
}

// *** Theme Margins *** //
@mixin margins($size: $spacing, $type: "top") {
  $s: $size;

  @if $size == "half" {
    $s: calc(#{$spacing} / 2);
  }

  @if $type == "vert" {
    margin-top: $s;
    margin-bottom: $s;

    @media (max-width: 768px) {
      margin-top: calc(#{$s} / 2);
      margin-bottom: calc(#{$s} / 2);
    }
  }

  @if $type == "top" {
    margin-top: $s;

    @media (max-width: 768px) {
      margin-top: calc(#{$s} / 2);
    }
  }

  @if $type == "bot" {
    margin-bottom: $s;

    @media (max-width: 768px) {
      margin-bottom: calc(#{$s} / 2);
    }
  }
}

// *** nLinkArea *** //
@mixin coverarea {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

// *** PX TO EM ***//
@mixin em($size, $basesize: 16, $important: false) {
  @if $important == true {
    font-size: calc($size / $basesize) * 1em !important;
  } @else {
    font-size: calc($size / $basesize) * 1em;
  }
}

// ********** END MIXINS CSS ********** //
