// ********** BLOG - ARCHIVE - TESTIMONIAL ********** //

// * Remove unneeded items from archive * //

.ex-name-testimonials {
  .listgrid {
    display: none;
  }

  .blog-testimonials {
    .row {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
}

// * //

.blog-testimonials {
  margin-bottom: 0 !important;

  &.blog-posts-grid {
    > h4 {
      border: 0;
      padding: 0;
    }
  }

  .slick-slider {
    margin-top: 20px;
  }

  .slick-list {
    margin: 0 -19px;
  }

  .slick-slide {
    margin: 0 19px;
    position: relative;

    .archive-testimonial {
      margin: 19px 0;
    }
  }

  .slick-active {
    + .slick-active {
      &::before {
        content: "";
        width: calc(100% + 56px);
        height: calc(100% + 38px);
        left: -28px;
        top: -19px;
        position: absolute;
        background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-review-shape.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        @media only screen and (max-width: 1280px) {
          display: none;
        }
      }

      ~ .slick-active {
        &::before {
          display: none;
        }
      }
    }
  }

  .blog-posts-grid-view {
    margin-inline: 15px;

    &:not(.slick-slider) {
      display: grid !important;
      grid-template-columns: repeat(3, 1fr);
      gap: 38px;

      @media only screen and (max-width: 1280px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media only screen and (max-width: 991px) {
        grid-template-columns: 1fr;
      }
    }

    &::before,
    &::after {
      display: none;
    }

    .blog-post-wrapper {
      width: 100%;
      padding: 0;
    }
  }

  @at-root .archive-testimonial {
    background-color: $col-lightgrey;
    border-radius: 10px;
    padding: 40px;
    height: 400px;
    overflow: auto;

    @at-root .testimonial-icons {
      margin: 0 0 20px;
      @include flex;

      @at-root .testimonial-stars {
        gap: 4px;
        @include flex;

        .star {
          width: 29px;
          height: 29px;
          background-color: #f6f6f6;
          border-radius: 3px;
          @include flex(center);

          img {
            filter: $filter-primary;
          }
        }
      }

      @at-root .testimonial-quote {
        filter: $filter-primary;
      }

      @at-root .testimonial-excerpt {
        p {
          margin: 0 0 15px;
          line-height: 1.5;
        }
      }
    }

    h3 {
      font-weight: 600;
      margin: 0 0 20px;
    }
  }
}

// ********** END BLOG - ARCHIVE - TESTIMONIAL ********** //
