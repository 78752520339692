.who-are-we {
  flex-direction: row;
  justify-content: center;

  .container > .nFlex {
    flex-wrap: wrap;
    margin-block: 10px;

    @media only screen and (max-width: 1469px) {
      justify-content: center;
    }
  }

  // *** Text Area styling *** //
  .text-wrapper {
    position: relative;
    left: 0;
    top: 0;

    @media only screen and (max-width: 1469px) {
      width: 90%;
    }

    .text-section {
      width: 600px;
      flex-direction: column;
      align-items: baseline;
      gap: 5px;

      @media only screen and (max-width: 1469px) {
        width: 100%;
      }

      h2 {
        color: $col-primary;
        font-size: 1.125em;
        margin-bottom: 15px;
      }

      h1 {
        font-size: 1.875em;
        margin-bottom: 15px;
      }

      p {
        margin-bottom: 15px;
      }

      p:first-of-type {
        font-weight: 500;
      }

      p:last-of-type {
        padding-bottom: 15px;
      }

      @media only screen and (max-width: 768px) {
        a {
          margin-inline: auto;
        }
      }
    }
  }

  // *** Text Area styling End*** //

  // *** Image Area styling *** //
  .image-section {
    width: 667px;
    position: relative;
    flex-direction: column;

    // *** image size & responsive *** //
    img {
      min-width: 667px;

      @media only screen and (max-width: 768px) {
        min-width: 100%;
      }

      @media only screen and (max-width: 477px) {
        display: none;
      }
    }

    // *** image size & responsive End *** //

    // *** Counter size & responsive *** //
    ul {
      position: absolute;
      bottom: 55px;
      display: flex;
      justify-content: space-around;
      gap: 55px;
      color: $col-text;
      background-color: #fafafa;
      padding-block: 20px;
      font-weight: 400;

      span {
        display: block;
        font-weight: 700;
        font-size: 1.5em;

        &::after {
          content: "+";
          color: $col-primary;
        }
      }

      li {
        flex-shrink: 0;
      }

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
      }

      @media only screen and (max-width: 564px) {
        bottom: 25px;
        gap: 10px;
      }

      @media only screen and (max-width: 477px) {
        position: relative;
        bottom: 0;
        margin-block: 10px;
        width: fit-content;
        justify-content: center;
        align-items: center;

        li {
          width: max-content;
          text-align: center;

          span {
            height: 25px;
          }
        }
      }
    }

    // *** Counter size & responsive End *** //
  }
}

// *** Image Area styling End*** //
