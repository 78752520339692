.service-portal {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  z-index: 1;
  text-align: center;
  width: 950%;
  max-width: 392px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: hidden;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    display: none;
  }

  > h2 {
    font-size: 1em;
    background-color: $col-black;
    background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/portal-header.jpg");
    background-size: cover;
    background-position: center;
    margin: 0;
    padding: 10px;
    transition: all 0.35s;

    i {
      margin-left: 10px;
      transition: all 0.35s;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &-dropdown {
    display: none;

    &-text {
      padding: 25px 35px;
    }

    h2 {
      font-size: 16px;
      margin: 0 0 15px;

      &::after {
        content: "";
        display: block;
        width: 51px;
        height: 3px;
        background-color: $col-gold;
        margin: 15px auto;
      }
    }

    p {
      font-size: 14px;
      margin: 0;
      color: #656565;
      font-weight: 500;
    }

    &-button {
      padding: 60px 35px;
      background-color: $col-black;
      background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/portal-bg.jpg");
      background-size: cover;
      background-position: center;
    }

    .nButton {
      font-size: 14px;
    }
  }

  &.nActive {
    h2 {
      i {
        transform: rotate(90deg);
      }
    }

    .service-portal-dropdown {
      display: block;
    }
  }
}
