// ********** MODULE - BRANDS CAROUSEL ********** //

.custom-brands {
  padding: 0;
  margin: 0 !important;
  background-color: #000;
  isolation: isolate;
  position: relative;
  @include paddings;

  &::before {
    content: "";
    background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-brands-bg.jpg");
    background-size: cover;
    background-position: center;
    z-index: -1;
    opacity: 0.12;
    @include coverarea;
  }

  h3 {
    color: #fff;
    font-size: var(--evo-font-size-h2);
  }

  .slick-slider {
    padding: 0;
    margin: 0;
  }

  .slick-arrow {
    top: 52px;
  }

  .slick-list {
    margin: 0 -19px;

    @media only screen and (max-width: 1280px) {
      margin: 0 -10px;
    }
  }

  .slick-slide {
    margin: 0 19px;

    @media only screen and (max-width: 1280px) {
      margin: 0 -10px;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      height: 105px;
      border-radius: 10px;
      background-color: #fff;
      padding: 10px;
      transition: background 0.35s;

      @media only screen and (max-width: 1280px) {
        border-radius: 0;
        padding: 10px 20px 10px 0;
      }

      .text-center {
        height: 100%;

        > div {
          height: 100%;
          @include flex(center);
        }
      }

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        display: block;
        mix-blend-mode: multiply;
      }

      &:hover {
        background-color: $col-primary;
      }
    }
  }
}

// ********** END MODULE - BRANDS CAROUSEL ********** //
