// **** Services We Offer Section ****//

.services-hero {
  position: relative;
  overflow: hidden;
  background-color: rgb(34 30 32 / 93%);
  @include paddings;

  img {
    object-fit: cover;
    z-index: -1;
    @include coverarea;
  }

  // * gradient on mobile bottom of accordian
  .services-text {
    @media only screen and (max-width: 769px) {
      &::after {
        content: "";
        text-align: center;
        position: absolute;
        width: 400%;
        height: 80%;
        bottom: 0;
        left: 0;
        z-index: 1;
        background: linear-gradient(180deg, #221e2000, #221e2000, #221e20, #221e20);
      }
    }

    h2 {
      margin: 0 0 20px;
      color: #fff;
    }

    p {
      font-weight: 600;
      margin-bottom: 0;
      color: #fff;
    }

    // * Line under heading with dots either side * //
    .divider {
      margin: 25px 0;
      height: 1px;
      width: 100%;
      background-color: $col-primary;
      position: relative;

      &::before,
      &::after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: $col-primary;
        border-radius: 50%;
        position: absolute;
        top: -3px;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    // * Line under heading with dots either side END * //

    .read-more-grid {
      @media only screen and (max-width: 769px) {
        display: grid;
        grid-template-rows: 0.4fr;
        transition: grid-template-rows 0.5s;
      }

      // * Grid responsiveness * //
      ul {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;
        gap: 17px;
        overflow: hidden;
        margin-top: 40px;

        @media only screen and (max-width: 1469px) {
          grid-template-columns: repeat(3, 1fr);
          justify-items: center;
        }

        @media only screen and (max-width: 991px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media only screen and (max-width: 481px) {
          grid-template-columns: 1fr;
        }

        @media only screen and (max-width: 769px) {
          max-height: auto;
        }

        // * Grid responsiveness END * //

        // * Styling the list with animated ticks * //
        li {
          gap: 15px 30px;
          width: 100%;
          color: #fff;
          font-weight: 500;
          @include flex(flex-start);

          &:empty {
            display: none;
          }

          &::before {
            content: "\f00c";
            font-family: FontAwesome;
            font-weight: 400;
            font-size: 25px;
            width: 43px;
            height: 43px;
            border-radius: 50%;
            background-color: #fff;
            color: $col-primary;
            flex-shrink: 0;
            transform: rotateY(0deg);
            transition: transform 1s;
            @include flex(center);
          }

          &:hover::before {
            transform: rotateY(360deg);
          }
        }

        // * Styling the list with animated ticks END * //
      }
    }

    // * Buttons at bottom of list with expand button on mobile * //
    .module-button {
      margin-top: 40px;
      gap: 10px;

      @media only screen and (max-width: 1469px) {
        margin-bottom: 0;

        .nButton {
          z-index: 2;
          padding: 25px;
        }
      }

      button {
        position: relative;
        display: none;

        &::before {
          content: "Expand";
        }

        &::after {
          content: "\f063";
          position: absolute;
          font-family: FontAwesome;
          font-weight: 400;
          font-size: 25px;
          width: 42px;
          height: 42px;
          bottom: -21px;
          left: 32%;
          border-radius: 50%;
          background-color: #fff;
          color: $col-primary;
          flex-shrink: 0;
          transition: transform 0.5s, color 0.35s;
          @include flex(center);
        }

        &:hover::after {
          background-color: $col-gold;
          color: #fff;
        }

        @media only screen and (max-width: 769px) {
          display: block;
        }
      }
    }

    &.nActive {
      &::after {
        background: linear-gradient(180deg, #221e2000, #221e2000, #ff008000);
      }

      .read-more-grid {
        grid-template-rows: 1fr;
      }

      button {
        &::before {
          content: "Collapse";
        }

        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }

  // * Buttons at bottom of list with expand button on mobile END * //
}

// **** Services We Offer Section End ****//
