// ********** BASE CSS ********** //

// *** Variables & Pseudo Selectors *** //

$col-gold: #a28e62;
$col-black: #221e20;
$col-lightgrey: #e9e5e2;

// Theme colours
$col-primary: var(--evo-brand-default, $col-gold);
$col-secondary: $col-black;
$col-tertiary: $col-lightgrey;
$col-text: var(--evo-text-col, $col-black);
$poppins: "Poppins", sans-serif;
$spacing: 80px;

// Social Media Colours
$social-fb: #1877f2;
$social-tw: #1da1f2;
$social-in: #0a66c2;
$social-ig: #833ab4;
$social-yt: #cd201f;
$social-pin: #bd081c;

// filters
$filter-primary: brightness(0) saturate(100%) invert(66%)
  sepia(5%) saturate(2712%) hue-rotate(3deg)
  brightness(86%) contrast(83%);

// *** Components *** //

h1,
.h1 {
  font-size: var(--evo-font-size-h1);
}

h2,
.h2 {
  font-size: var(--evo-font-size-h2);
}

h3,
.h3 {
  font-size: var(--evo-font-size-h3);
}

h4,
.h4 {
  font-size: var(--evo-font-size-h4);
}

.text-color { color: $col-text; }
.text-white { color: #fff; }

.text-uppercase { text-transform: uppercase; }

.text-large { font-size: 1.125em; } // 16px -> 18px
.text-larger { font-size: 1.5em; } // 24px -> 36px
.text-small { font-size: 0.9em; }
.text-smaller { font-size: 0.75em; }

.text-medium { font-weight: 500; }
.text-semibold { font-weight: 600; }
.text-bold { font-weight: 700; }

.bg-black { background: #000; }
.bg-lightgrey { background-color: $col-lightgrey; }

.p-default { @include paddings; }
.p-top-default { @include paddings($type: "top"); }
.p-bot-default { @include paddings($type: "bot"); }

.m-default { @include margins($type: "vert"); }
.m-top-default { @include margins($type: "top"); }
.m-bot-default { @include margins($type: "bot"); }

.p-half { @include paddings("half"); }
.p-top-half { @include paddings("half", "top"); }
.p-bot-half { @include paddings("half", "bot"); }

.m-half { @include margins("half", "vert"); }
.m-top-half { @include margins("half", "top"); }
.m-bot-half { @include margins("half", "bot"); }

@media only screen and (max-width: 991px) {
  .flex-col-sm {
    flex-direction: column;
  }

  .flex-col-sm > div {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .flex-col-xs {
    flex-direction: column;
  }

  .flex-col-xs > div {
    width: 100%;
  }

  .p-top-default { padding-top: calc(#{$spacing} / 2); }
  .p-bot-default { padding-bottom: calc(#{$spacing} / 2); }

  .m-top-default { margin-top: calc(#{$spacing} / 2); }
  .m-bot-default { margin-bottom: calc(#{$spacing} / 2); }
}

// *** Defaults *** //

img {
  image-rendering: optimize-contrast; // fix chrome blurry images when scaled down
}

.nFlex {
  @include flex;
}

.nFlex-center {
  @include flex("center");
}

.nRel {
  position: relative;
}

.nLinkArea {
  @include coverarea;
}

.nList {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0;
    padding: 0;
  }
}

.ex-loggedout .nLoggedIn,
.ex-loggedin .nLoggedOut {
  display: none;
}

.nButton {
  font-weight: 700;
  display: inline-block;
  background: $col-primary;
  padding: 12px 55px;
  text-transform: capitalize;
  color: #fff;
  text-align: center;
  border: hidden;
  border-radius: 25px;
  transition: all 0.35s linear;

  @at-root {
    .nbWhite {
      background: #fff;
      color: #000;
    }
  }

  &:hover {
    background: $col-secondary;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
  }
}

.no-top-margin {
  margin-top: 0;
}

.catalog-grid .container {
  padding: 0 15px;
}

.container {
  .container {
    padding: 0;
    width: auto;
  }
}

// ********** END BASE CSS ********** //
