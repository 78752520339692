/* stylelint-disable no-duplicate-selectors */
// ********** HEADER ********** //

// *** Top Bar *** //

.headersection-1,
.top-bar {
  background-color: #f4f4f4;
}

.top-bar {
  padding: 6px 0;
  @include em(13);

  ul {
    @include flex(flex-start);
  }

  a {
    color: #525150;

    i {
      margin-right: 12px;
      color: $col-primary;
      @include em(16, 13);
    }

    &:hover,
    &:focus-visible {
      color: $col-primary;
    }
  }

  .divider {
    width: 2px;
    height: 12px;
    background-color: #6f6e6e;
    margin: 0 30px;
  }
}

// *** End Top Bar *** //

.header-full {
  .vertical-align {
    justify-content: space-between;

    @media only screen and (max-width: 991px) {
      margin-inline: -8px;
    }

    &::before,
    &::after {
      display: none;
    }

    > div {
      width: auto;

      @media only screen and (max-width: 991px) {
        padding-inline: 8px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    font-size: 0.8em;
  }
}

// *** Logo *** //

.header-full .logo img {
  width: 111px !important;
  max-width: 100% !important;
  height: auto !important;
  max-height: none !important;
}

.header-full .logo_container {
  &:hover,
  &:focus-within {
    filter: $filter-primary;
  }
}

@media only screen and (max-width: 768px) {
  .header-mobile .logo img {
    max-width: 100px !important;
  }
}

// *** End Logo *** //

// *** Search *** //

.header-full {
  .search_container {
    flex-grow: 1;

    section.search {
      font-size: 1em;

      .search-form {
        .form-group input {
          border-color: #e3e3e3;
          height: 45px;
          border-radius: 22px;
          padding: 10px 55px 10px 36px;
          @include em(14);

          &:focus {
            border-color: $col-primary;
          }

          &::placeholder {
            color: #a9a9a9;
          }
        }

        button[type="submit"] {
          width: 45px;
          height: 45px;
          background-color: $col-primary;
          border-radius: 50%;
          padding: 0;
          right: 0;
          top: 0;

          i {
            background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-search.svg");
            width: 16px;
            height: 16px;
            background-size: contain;
            filter: brightness(0) invert(1);

            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}

// *** End Search *** //

// *** Head Tel *** //

.head-tel {
  a {
    font-weight: 600;
    color: $col-text;
    @include em(18);
    @include flex;

    &:hover,
    &:focus-visible {
      color: $col-primary;
    }
  }

  img {
    margin-right: 20px;
    filter: $filter-primary;

    @media only screen and (max-width: 991px) {
      margin-right: 5px;
    }
  }
}

// *** End Head Tel *** //

// *** Account Tools *** //

.toolbar {
  .account-btn {
    margin: 0 !important;
    @include flex;

    &:focus-within {
      background-color: $col-tertiary;
      border-radius: 25px;
    }

    .user-icon {
      padding: 0;
      width: 46px;
      height: 46px;
      background-color: $col-tertiary;
      border-radius: 50%;
      @include flex(center);

      @media only screen and (max-width: 991px) {
        width: 32px;
        height: 32px;
      }

      &::before {
        content: "";
        background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-account2.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 19px;
        height: 21px;
        display: block;
        filter: $filter-primary;

        @media only screen and (max-width: 991px) {
          width: 12px;
        }
      }
    }

    > span {
      display: flex;
      flex-direction: column-reverse;
      font-size: 1em;
      margin-left: 15px !important;

      @media only screen and (max-width: 991px) {
        margin-left: 5px !important;
      }

      span {
        strong {
          font-weight: 600;
        }
      }

      .line2 {
        margin-bottom: 2px;
      }

      .line1 {
        color: #939393;
        @include em(14);
      }

      br {
        display: none;
      }
    }
  }
}

// *** End Account Tools *** //

// *** Minicart *** //

.toolbar {
  .cart-btn {
    margin: 0;

    &:focus-within {
      background-color: $col-tertiary;
      border-radius: 25px;
    }

    > .btn {
      background: none;
      padding: 0;
      border-radius: 0;
      display: grid;
      grid-template-areas:
        "g1 g2"
        "g1 g3";
      grid-gap: 6px 15px;
      align-items: center;
      font-size: 1em;

      @media only screen and (max-width: 991px) {
        grid-gap: 6px 5px;
      }

      a {
        color: $col-primary;
      }

      i {
        padding: 0;
        width: 46px;
        height: 46px;
        background-color: $col-tertiary;
        border-radius: 50%;
        margin-right: 0;
        grid-area: g1;
        @include flex(center);

        @media only screen and (max-width: 991px) {
          width: 32px;
          height: 32px;
        }

        &::before {
          content: "";
          background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-account2.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 19px;
          height: 21px;
          display: block;
          filter: $filter-primary;

          @media only screen and (max-width: 991px) {
            width: 12px;
          }
        }
      }

      > .minicart_count {
        position: static;
        font-size: 1em;
        font-weight: 600;
        padding: 0;
        grid-area: g2;
        align-self: end;
        background-color: transparent;
        color: $col-text;
        margin-top: 3px;

        &::before {
          content: "Your Basket (";

          @media only screen and (max-width: 991px) {
            content: "Basket (";
          }
        }

        &::after {
          content: ")";
        }
      }

      > .no-price {
        line-height: 1.2;
      }

      > .minicart_total {
        color: #939393;
        line-height: 1.5;
        grid-area: g3;
        align-self: start;
        @include em(14);

        &::before {
          content: "Total ";
        }
      }
    }

    &:hover {
      > .btn {
        background: transparent;
      }
    }
  }
}

// *** End Minicart *** //

// *** Navigation *** //

.menu {
  ul.catalog {
    > li {
      padding: 0;

      &:focus-within {
        background-color: #83734e;
      }

      &.has-submenu {
        > a {
          letter-spacing: 0;

          &::after {
            content: "";
            background: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-arrow-down.svg") no-repeat center;
            width: 10px;
            height: 7px;
            position: absolute;
            right: 12px;
            top: calc(50% - 3px);
            filter: brightness(0) invert(1);
          }
        }
      }

      &:not(.hide) {
        display: inline-block !important;
      }

      > a {
        color: #fff;
        font-weight: 600;
        font-size: 1.042em;
        padding: 11px 32px;
        position: relative;

        &:hover,
        &:focus-visible {
          background-color: transparent;

          > a {
            color: $col-text;
          }
        }
      }
    }
  }
}

// *** End Navigation *** //

// ********** END HEADER ********** //
