.contact-header {
  padding: 90px 0 40px;

  @media only screen and (max-width: 1366px) {
    padding: 90px 15px 40px;
  }

  &::after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: $col-primary;
    display: block;
    margin: 40px 0 0;
  }

  > div {
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      gap: 20px;

      .contact-title {
        width: 100%;
        text-align: center;

        p {
          max-width: 100%;
        }
      }

      .contact-info {
        width: 100%;
        justify-content: space-between;

        .contact-address {
          width: 100%;

          p {
            padding: 0;
          }
        }
      }
    }

    @media only screen and (max-width: 481px) {
      .contact-info {
        flex-direction: column;
        gap: 20px;
      }
    }
  }

  .contact-title {
    width: 50%;

    h2 {
      margin: 0 0 20px;
    }

    p {
      max-width: 500px;
      margin: 0;
    }
  }

  .contact-info {
    width: 55%;
    display: flex;
    gap: 10px;

    .contact-address {
      width: 50%;
      display: flex;

      .contact-icon {
        width: 30px;
        margin-right: 0;
        display: inline;

        i {
          font-size: 25px;
          filter: $filter-primary;
        }
      }

      p {
        padding: 0 50px 0 0;
        font-weight: 600;
        margin: 0;

        @include em(16);
      }
    }
  }

  .contact-details {
    min-width: 211px;

    .contact-block {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;

      p,
      .hours-description {
        font-weight: 600;
        margin: 0;
      }

      a {
        color: $col-text;

        &:hover,
        &:focus-within {
          color: $col-primary;
          text-decoration: none;
        }
      }

      .contact-icon {
        margin-right: 0;
        display: inline;

        i::before {
          font-size: 18px;
          filter: $filter-primary;
        }
      }
    }

    .opening-hours {
      .hours-icon {
        background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-clock.svg");
        filter: $filter-primary;
        display: inline-block;
        height: 15px;
        width: 15px;
        background-repeat: no-repeat;
      }

      .hours-description {
        display: block;
        line-height: 1.7;
      }
    }
  }
}

.contact-form {
  &::after {
    content: "";
    height: 3px;
    width: 100%;
    background-color: $col-secondary;
    display: block;
  }

  .container {
    padding: 0;
  }
}

.form-section {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 0 60px;

  @media only screen and (max-width: 1366px) {
    padding: 0 15px;
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 481px) {
    grid-template-columns: 1fr;
  }

  .form-group {
    margin: 0;
    padding: 0 35px 25px 0;

    @media only screen and (max-width: 481px) {
      padding: 0 0 20px;

      label {
        margin: 0 0 5px;
      }

      input,
      select {
        height: 40px !important;
      }
    }

    label span {
      color: #ac998a;
    }

    input,
    select,
    textarea {
      height: 60px;
      border-radius: 10px;
      padding: 6px 25px;
    }

    &.additional-info {
      width: 100%;
      grid-column: 1/span 2;

      @media only screen and (max-width: 481px) {
        grid-column: 1;
      }

      textarea {
        height: 160px;
        overflow: auto;
      }
    }
  }

  .g-recaptcha {
    margin: 34px 0 0;
    grid-column: 1/span 2;

    @media only screen and (max-width: 481px) {
      grid-column: 1;
    }
  }
}

.location-map {
  iframe {
    width: 100%;
    height: 400px;
  }
}
