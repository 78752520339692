.hero-banner {
  position: relative;
  background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-hero-banner.jpg");
  background-size: cover;
  background-position: center center;
  z-index: 0;
  isolation: isolate;

  &::after {
    content: "";
    background:
      linear-gradient(
        90deg,
        rgb(0 0 0 / 100%) 0%,
        rgb(0 0 0 / 100%) 50%,
        rgb(0 0 0 / 0%) 70%,
        rgb(0 0 0 / 0%) 100%
      );
    opacity: 0.79;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 242px;
  }

  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 242px;
    z-index: 1;

    h1 {
      font-size: 1.875em;
    }
  }
}
