// ********** MODULE - CUSTOM MERCHANDISING ********* //

.custom-merchandising {
  margin: 0;

  h3 {
    margin: 0 0 40px;
    font-size: var(--evo-font-size-h2);
    @include flex(center);

    span {
      width: 7px;
      height: 7px;
      display: block;
      background-color: $col-primary;
      border-radius: 50%;
      flex-shrink: 0;

      &:first-child {
        margin-right: 20px;
      }

      &:last-child {
        margin-left: 20px;
      }
    }

    &::before,
    &::after {
      content: "";
      width: 110px;
      height: 1px;
      display: block;
      background-color: $col-primary;
    }
  }

  .slick-slider {
    padding: 0;
    margin: 0;
  }

  .slick-list {
    margin: 0 -12px;
  }

  .slick-slide {
    margin: 0 12px;
  }

  .slick-arrow {
    top: 105px;
  }

  &.catalog-grid {
    .tileparent {
      padding: 0;
    }

    .tile {
      padding: 0;
      margin: 0;
      max-width: none;

      .brand-label,
      .uom,
      .indicators-grid,
      .product-compare-action,
      .addtoquicklist-wrapper,
      .price-label-inc-vat {
        display: none !important;
      }

      a {
        &:focus-visible {
          .imgthumbnail {
            opacity: 0.5;
          }
        }
      }

      .imgthumbnail {
        height: 208px;
        background-color: #f4f4f4;
        padding: 20px;
        border-radius: 5px;
        margin: 0 0 20px;
        @include flex(center);

        img {
          mix-blend-mode: multiply;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }

      .product-name {
        margin: 0 0 20px;
        font-size: 1em;
        line-height: 1.5;

        a {
          color: #494949;

          &:hover,
          &:focus-visible {
            color: $col-primary;
          }
        }
      }

      .product-sku,
      .product-category {
        font-size: 0.875em;
        color: #939393;

        strong {
          font-weight: 400;

          &::after {
            content: ":";
          }
        }

        a {
          color: #939393;

          &:hover,
          &:focus-visible {
            color: $col-primary;
          }
        }
      }

      .price-label {
        color: #221e20;
        font-weight: 600;
        font-size: 1.5em;
        margin-top: 15px;
      }

      .footer {
        .footer-wrapper {
          min-height: 0;
        }
      }

      .buttons.group {
        gap: 5px;
        margin-top: 15px;
        @include flex;

        &::after {
          display: none;
        }

        input {
          height: 42px;
          width: 45px;
          margin: 0;
          border-radius: 22px;
          @include em(14);
        }

        .btn {
          margin: 0;
          flex-grow: 1;
          min-height: 42px;
          border-radius: 22px;
          font-weight: 700;

          &::before {
            content: "";
            background: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-basket2.svg") no-repeat center;
            width: 14px;
            height: 13px;
            display: inline-block;
            filter: brightness(0) invert(1);
            margin-right: 10px;
          }

          &:hover,
          &:focus-visible {
            background-color: $col-secondary;

            &::before {
              filter: $filter-primary;
            }
          }
        }
      }
    }
  }
}

// ********** MODULE - CUSTOM MERCHANDISING ********* //
