.about-us-wrapper {
  background-color: $col-tertiary;
  padding-bottom: 120px;
}

.about-us-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 1070px) {
    flex-direction: column;
    justify-content: center;
  }

  .text-section {
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    width: 50%;

    @media only screen and (max-width: 1070px) {
      width: 100%;
      padding-bottom: 20px;
    }

    h3 {
      color: $col-primary;
      margin-bottom: 15px;
    }

    h1 {
      color: $col-secondary;
      font-size: 1.875em;
      margin: 0;
    }

    p {
      margin: 0;
    }
  }

  .image-section {
    height: auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 20px;
    overflow: hidden;

    .lower-images {
      gap: 20px;
      overflow: hidden;
    }

    img {
      width: 100%;
      border-radius: 5px;
      transition: 0.35s ease;

      &:hover {
        scale: 1.05;
      }
    }
  }
}

.solution-cards {
  position: relative;
  top: -100px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  @media only screen and (max-width: 1470px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }

  .first-card {
    height: 350px;
    position: relative;
    border-radius: 5px;
    background-color: $col-secondary;
    overflow: hidden;

    &:hover {
      img {
        scale: 1.2;
        transform: rotate(5deg);
        filter: brightness(0) invert(1) blur(5px);
      }
    }

    img {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.15;
      object-fit: contain;
      filter: brightness(0) invert(1);
      padding: 10px 40px 40px;
      rotate: -90deg;
      transition: 0.35s ease;
    }

    .card-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 40px 30px 0 40px;

      @media only screen and (max-width: 400px) {
        padding: 20px 15px 0 20px;
      }

      h3 {
        color: #fff;
        margin-bottom: 0;
        font-size: 1.625em;
        font-weight: 700;
      }

      p {
        color: #fff;
        margin-top: 15px;
        line-height: 24px;
      }
    }
  }

  .card {
    height: 350px;
    position: relative;
    border-radius: 5px;
    background-color: $col-secondary;
    overflow: hidden;

    &:hover {
      img {
        scale: 1.2;
        transform: rotate(5deg);
        filter: brightness(0) invert(1) blur(5px);
      }
    }

    &:nth-child(6) {
      img {
        rotate: -90deg;
      }
    }

    img {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.15;
      object-fit: contain;
      padding: 40px;
      filter: brightness(0) invert(1);
      transition: 0.35s ease;
    }

    .card-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 30px;

      h3 {
        font-size: 1.375em;
        color: #fff;
        margin: 15px 0;

        &::after {
          content: "";
          display: block;
          width: 53px;
          height: 3px;
          background-color: $col-primary;
          margin: 25px auto 5px;
        }
      }

      p {
        text-align: center;
        color: #fff;
      }
    }
  }
}

.card-wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 1350px) {
    flex-direction: column;
    gap: 20px;
  }

  .card {
    background-color: $col-tertiary;
    border-radius: 9px;
    padding: 60px;
    width: 48%;

    @media only screen and (max-width: 1305px) {
      width: 100%;
    }

    h4 {
      font-size: 1.375em;
      font-weight: 700;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 12px;
    }

    .contact-links {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;

      @media only screen and (max-width: 672px) {
        flex-direction: column;
        gap: 20px;
      }

      * {
        color: $col-secondary;
        transition: 0.35s ease;

        i {
          color: $col-primary;
        }

        &:hover {
          color: $col-primary;
          text-decoration: none;
        }
      }
    }
  }

  .sales-team {
    .contact-description p {
      cursor: pointer;
      margin-bottom: 0;
    }

    .contact-email,
    .contact-phone {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;

      i::before {
        font-size: 20px;
      }
    }

    .contact-chat::before {
      content: "\f27a";
      font-family: FontAwesome;
      display: inline-block;
      font-size: 20px;
      margin-right: 20px;
      filter:
        brightness(0) saturate(100%) invert(66%) sepia(5%)
        saturate(2712%) hue-rotate(3deg) brightness(86%) contrast(83%);
    }
  }

  .service-team .contact-links {
    text-align: center;

    a::after {
      content: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-arrow-right.svg");
      display: inline-block;
      width: 22px;
      height: 13px;
      margin-left: 20px;
      filter:
        brightness(0) saturate(100%) invert(66%) sepia(5%)
        saturate(2712%) hue-rotate(3deg) brightness(86%) contrast(83%);
    }
  }
}
