.services-info {
  margin: 60px 0 20px;

  p {
    font-weight: 400 !important;
  }
}

.ex-name-servicesweoffer {
  .readmore {
    display: none !important;
  }

  .read-more-grid {
    @media only screen and (max-width: 769px) {
      grid-template-rows: 1fr !important;
    }
  }

  .services-hero .services-text::after {
    display: none;
  }
}

.loler-puwer {
  h3,
  li {
    color: #fff;
  }

  h3 {
    margin: 5px 0 10px;
  }

  p {
    font-weight: 400 !important;
  }
}

.services-page {
  padding: 12px 80px;
  font-weight: 400 !important;
}

.services-form-title {
  padding: 70px 15px 40px;
}

.ex-name-servicesweoffer {
  .contact-form::after {
    display: none;
  }

  .form-section {
    padding: 0 15px;
  }
}
