h1,
.h1 {
  font-size: var(--evo-font-size-h1);
}

h2,
.h2 {
  font-size: var(--evo-font-size-h2);
}

h3,
.h3 {
  font-size: var(--evo-font-size-h3);
}

h4,
.h4 {
  font-size: var(--evo-font-size-h4);
}

.text-color {
  color: var(--evo-text-col, #221e20);
}

.text-white {
  color: #fff;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-large {
  font-size: 1.125em;
}

.text-larger {
  font-size: 1.5em;
}

.text-small {
  font-size: 0.9em;
}

.text-smaller {
  font-size: 0.75em;
}

.text-medium {
  font-weight: 500;
}

.text-semibold {
  font-weight: 600;
}

.text-bold {
  font-weight: 700;
}

.bg-black {
  background: #000;
}

.bg-lightgrey {
  background-color: #e9e5e2;
}

.p-default {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 768px) {
  .p-default {
    padding-top: calc(80px / 2);
    padding-bottom: calc(80px / 2);
  }
}

.p-top-default {
  padding-top: 80px;
}
@media (max-width: 768px) {
  .p-top-default {
    padding-top: calc(80px / 2);
  }
}

.p-bot-default {
  padding-bottom: 80px;
}
@media (max-width: 768px) {
  .p-bot-default {
    padding-bottom: calc(80px / 2);
  }
}

.m-default {
  margin-top: 80px;
  margin-bottom: 80px;
}
@media (max-width: 768px) {
  .m-default {
    margin-top: calc(80px / 2);
    margin-bottom: calc(80px / 2);
  }
}

.m-top-default {
  margin-top: 80px;
}
@media (max-width: 768px) {
  .m-top-default {
    margin-top: calc(80px / 2);
  }
}

.m-bot-default {
  margin-bottom: 80px;
}
@media (max-width: 768px) {
  .m-bot-default {
    margin-bottom: calc(80px / 2);
  }
}

.p-half {
  padding-top: calc(80px / 2);
  padding-bottom: calc(80px / 2);
}
@media (max-width: 768px) {
  .p-half {
    padding-top: calc(calc(80px / 2) / 2);
    padding-bottom: calc(calc(80px / 2) / 2);
  }
}

.p-top-half {
  padding-top: calc(80px / 2);
}
@media (max-width: 768px) {
  .p-top-half {
    padding-top: calc(calc(80px / 2) / 2);
  }
}

.p-bot-half {
  padding-bottom: calc(80px / 2);
}
@media (max-width: 768px) {
  .p-bot-half {
    padding-bottom: calc(calc(80px / 2) / 2);
  }
}

.m-half {
  margin-top: calc(80px / 2);
  margin-bottom: calc(80px / 2);
}
@media (max-width: 768px) {
  .m-half {
    margin-top: calc(calc(80px / 2) / 2);
    margin-bottom: calc(calc(80px / 2) / 2);
  }
}

.m-top-half {
  margin-top: calc(80px / 2);
}
@media (max-width: 768px) {
  .m-top-half {
    margin-top: calc(calc(80px / 2) / 2);
  }
}

.m-bot-half {
  margin-bottom: calc(80px / 2);
}
@media (max-width: 768px) {
  .m-bot-half {
    margin-bottom: calc(calc(80px / 2) / 2);
  }
}

@media only screen and (max-width: 991px) {
  .flex-col-sm {
    flex-direction: column;
  }
  .flex-col-sm > div {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .flex-col-xs {
    flex-direction: column;
  }
  .flex-col-xs > div {
    width: 100%;
  }
  .p-top-default {
    padding-top: calc(80px / 2);
  }
  .p-bot-default {
    padding-bottom: calc(80px / 2);
  }
  .m-top-default {
    margin-top: calc(80px / 2);
  }
  .m-bot-default {
    margin-bottom: calc(80px / 2);
  }
}
img {
  image-rendering: optimize-contrast;
}

.nFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nFlex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nRel {
  position: relative;
}

.nLinkArea {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.nList {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nList li {
  margin: 0;
  padding: 0;
}

.ex-loggedout .nLoggedIn,
.ex-loggedin .nLoggedOut {
  display: none;
}

.nButton {
  font-weight: 700;
  display: inline-block;
  background: var(--evo-brand-default, #a28e62);
  padding: 12px 55px;
  text-transform: capitalize;
  color: #fff;
  text-align: center;
  border: hidden;
  border-radius: 25px;
  transition: all 0.35s linear;
}
.nbWhite {
  background: #fff;
  color: #000;
}

.nButton:hover {
  background: #221e20;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.no-top-margin {
  margin-top: 0;
}

.catalog-grid .container {
  padding: 0 15px;
}

.container .container {
  padding: 0;
  width: auto;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Icons */
/**
  Slider
*/
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/*=================================
  Previous and Next icon buttons
==================================*/
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  border: none;
  transform: translate(0, -50%);
}
.slick-prev:hover .slick-prev-icon,
.slick-prev:hover .slick-next-icon, .slick-prev:focus .slick-prev-icon,
.slick-prev:focus .slick-next-icon,
.slick-next:hover .slick-prev-icon,
.slick-next:hover .slick-next-icon,
.slick-next:focus .slick-prev-icon,
.slick-next:focus .slick-next-icon {
  opacity: 1;
}
.slick-prev:focus,
.slick-next:focus {
  top: calc(50% - 1px);
}
.slick-prev:focus .slick-prev-icon,
.slick-prev:focus .slick-next-icon,
.slick-next:focus .slick-prev-icon,
.slick-next:focus .slick-next-icon {
  color: orange;
  font-size: 28px;
  margin-left: -2px;
}
.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  cursor: default;
}
.slick-prev.slick-disabled .slick-prev-icon,
.slick-prev.slick-disabled .slick-next-icon,
.slick-next.slick-disabled .slick-prev-icon,
.slick-next.slick-disabled .slick-next-icon {
  opacity: 0.25;
}
.slick-prev .slick-prev-icon,
.slick-prev .slick-next-icon,
.slick-next .slick-prev-icon,
.slick-next .slick-next-icon {
  display: block;
  color: var(--evo-brand-default, #a28e62);
  opacity: 1;
  font-family: fontawesome;
  font-size: 24px;
  line-height: 1;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev .slick-prev-icon:before {
  content: "\f053";
}
[dir=rtl] .slick-prev .slick-prev-icon:before {
  content: "\f054";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next .slick-next-icon:before {
  content: "\f054";
}
[dir=rtl] .slick-next .slick-next-icon:before {
  content: "\f053";
}

/*==========================
  Slide navigation dots
===========================*/
.slick-slider {
  margin-bottom: 30px;
}
.slick-slider.slick-dotted {
  margin-bottom: 60px;
}

.slick-dots {
  position: absolute;
  bottom: -30px;
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
}
.slick-dots li button {
  display: block;
  height: 20px;
  width: 20px;
  margin-top: -4px;
  margin-left: -4px;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  border: 0;
  background: transparent;
  cursor: pointer;
}
.slick-dots li button:hover .slick-dot-icon, .slick-dots li button:focus .slick-dot-icon {
  opacity: 1;
}
.slick-dots li button:focus .slick-dot-icon:before {
  color: orange;
}
.slick-dots li button .slick-dot-icon {
  color: var(--evo-brand-default, #a28e62);
  opacity: 0.25;
}
.slick-dots li button .slick-dot-icon:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\f111";
  font-family: fontawesome;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  transition: all 0.05s linear;
}
.slick-dots li.slick-active button:focus .slick-dot-icon {
  color: orange;
  opacity: 1;
}
.slick-dots li.slick-active button .slick-dot-icon {
  color: #221e20;
  opacity: 1;
}
.slick-dots li.slick-active button .slick-dot-icon:before {
  margin-top: -3px;
  margin-left: -2px;
  font-size: 18px;
}

/**
 Improved .sr-only class by ffoodd: https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034
 */
.slick-sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

/*===========================
  Pause/play icon button
============================*/
.slick-autoplay-toggle-button {
  position: absolute;
  left: 5px;
  bottom: -32px;
  z-index: 10;
  opacity: 1;
  background: none;
  border: 0;
  cursor: pointer;
  color: var(--evo-brand-default, #a28e62);
}
.slick-autoplay-toggle-button:hover, .slick-autoplay-toggle-button:focus {
  opacity: 1;
}
.slick-autoplay-toggle-button:focus {
  color: orange;
}
.slick-autoplay-toggle-button .slick-pause-icon:before {
  content: "\f04c";
  width: 20px;
  height: 20px;
  font-family: fontawesome;
  font-size: 18px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
}
.slick-autoplay-toggle-button .slick-play-icon:before {
  content: "\f04b";
  width: 20px;
  height: 20px;
  font-family: fontawesome;
  font-size: 18px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
}

.slick-slider {
  padding: 0;
}

.slick-arrow {
  z-index: 1;
  background-color: var(--evo-text-col, #221e20);
  width: 25px;
  height: 55px;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slick-arrow:hover, .slick-arrow:focus-visible {
  background-color: var(--evo-brand-default, #a28e62);
}

.slick-prev,
.slick-next {
  top: calc(50% - 12px);
}
.slick-prev .slick-prev-icon,
.slick-prev .slick-next-icon,
.slick-next .slick-prev-icon,
.slick-next .slick-next-icon {
  font-size: 1.25rem;
}
.slick-prev .slick-prev-icon::before,
.slick-prev .slick-next-icon::before,
.slick-next .slick-prev-icon::before,
.slick-next .slick-next-icon::before {
  content: "";
  background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-right-arrow.svg");
  background-size: contain;
  width: 9px;
  height: 14px;
  display: block;
  filter: brightness(0) invert(1);
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  top: calc(50% - 12px);
}
.slick-prev:hover .slick-prev-icon,
.slick-prev:hover .slick-next-icon, .slick-prev:focus .slick-prev-icon,
.slick-prev:focus .slick-next-icon,
.slick-next:hover .slick-prev-icon,
.slick-next:hover .slick-next-icon,
.slick-next:focus .slick-prev-icon,
.slick-next:focus .slick-next-icon {
  color: #221e20;
  margin: 0;
  font-size: 1.25rem;
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

.slick-prev-icon::before {
  transform: rotate(180deg);
}

.slick-autoplay-toggle-button {
  z-index: 1;
}
.slick-autoplay-toggle-button:hover {
  opacity: 0.75;
}
.slick-autoplay-toggle-button:focus {
  color: #221e20;
}

/* stylelint-disable no-duplicate-selectors */
.headersection-1,
.top-bar {
  background-color: #f4f4f4;
}

.top-bar {
  padding: 6px 0;
  font-size: 0.8125em;
}
.top-bar ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.top-bar a {
  color: #525150;
}
.top-bar a i {
  margin-right: 12px;
  color: var(--evo-brand-default, #a28e62);
  font-size: 1.2307692308em;
}
.top-bar a:hover, .top-bar a:focus-visible {
  color: var(--evo-brand-default, #a28e62);
}
.top-bar .divider {
  width: 2px;
  height: 12px;
  background-color: #6f6e6e;
  margin: 0 30px;
}

.header-full .vertical-align {
  justify-content: space-between;
}
@media only screen and (max-width: 991px) {
  .header-full .vertical-align {
    margin-inline: -8px;
  }
}
.header-full .vertical-align::before, .header-full .vertical-align::after {
  display: none;
}
.header-full .vertical-align > div {
  width: auto;
}
@media only screen and (max-width: 991px) {
  .header-full .vertical-align > div {
    padding-inline: 8px;
  }
}
@media only screen and (max-width: 991px) {
  .header-full {
    font-size: 0.8em;
  }
}

.header-full .logo img {
  width: 111px !important;
  max-width: 100% !important;
  height: auto !important;
  max-height: none !important;
}

.header-full .logo_container:hover, .header-full .logo_container:focus-within {
  filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(2712%) hue-rotate(3deg) brightness(86%) contrast(83%);
}

@media only screen and (max-width: 768px) {
  .header-mobile .logo img {
    max-width: 100px !important;
  }
}
.header-full .search_container {
  flex-grow: 1;
}
.header-full .search_container section.search {
  font-size: 1em;
}
.header-full .search_container section.search .search-form .form-group input {
  border-color: #e3e3e3;
  height: 45px;
  border-radius: 22px;
  padding: 10px 55px 10px 36px;
  font-size: 0.875em;
}
.header-full .search_container section.search .search-form .form-group input:focus {
  border-color: var(--evo-brand-default, #a28e62);
}
.header-full .search_container section.search .search-form .form-group input::placeholder {
  color: #a9a9a9;
}
.header-full .search_container section.search .search-form button[type=submit] {
  width: 45px;
  height: 45px;
  background-color: var(--evo-brand-default, #a28e62);
  border-radius: 50%;
  padding: 0;
  right: 0;
  top: 0;
}
.header-full .search_container section.search .search-form button[type=submit] i {
  background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-search.svg");
  width: 16px;
  height: 16px;
  background-size: contain;
  filter: brightness(0) invert(1);
}
.header-full .search_container section.search .search-form button[type=submit] i::before {
  display: none;
}

.head-tel a {
  font-weight: 600;
  color: var(--evo-text-col, #221e20);
  font-size: 1.125em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.head-tel a:hover, .head-tel a:focus-visible {
  color: var(--evo-brand-default, #a28e62);
}
.head-tel img {
  margin-right: 20px;
  filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(2712%) hue-rotate(3deg) brightness(86%) contrast(83%);
}
@media only screen and (max-width: 991px) {
  .head-tel img {
    margin-right: 5px;
  }
}

.toolbar .account-btn {
  margin: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.toolbar .account-btn:focus-within {
  background-color: #e9e5e2;
  border-radius: 25px;
}
.toolbar .account-btn .user-icon {
  padding: 0;
  width: 46px;
  height: 46px;
  background-color: #e9e5e2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 991px) {
  .toolbar .account-btn .user-icon {
    width: 32px;
    height: 32px;
  }
}
.toolbar .account-btn .user-icon::before {
  content: "";
  background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-account2.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 19px;
  height: 21px;
  display: block;
  filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(2712%) hue-rotate(3deg) brightness(86%) contrast(83%);
}
@media only screen and (max-width: 991px) {
  .toolbar .account-btn .user-icon::before {
    width: 12px;
  }
}
.toolbar .account-btn > span {
  display: flex;
  flex-direction: column-reverse;
  font-size: 1em;
  margin-left: 15px !important;
}
@media only screen and (max-width: 991px) {
  .toolbar .account-btn > span {
    margin-left: 5px !important;
  }
}
.toolbar .account-btn > span span strong {
  font-weight: 600;
}
.toolbar .account-btn > span .line2 {
  margin-bottom: 2px;
}
.toolbar .account-btn > span .line1 {
  color: #939393;
  font-size: 0.875em;
}
.toolbar .account-btn > span br {
  display: none;
}

.toolbar .cart-btn {
  margin: 0;
}
.toolbar .cart-btn:focus-within {
  background-color: #e9e5e2;
  border-radius: 25px;
}
.toolbar .cart-btn > .btn {
  background: none;
  padding: 0;
  border-radius: 0;
  display: grid;
  grid-template-areas: "g1 g2" "g1 g3";
  grid-gap: 6px 15px;
  align-items: center;
  font-size: 1em;
}
@media only screen and (max-width: 991px) {
  .toolbar .cart-btn > .btn {
    grid-gap: 6px 5px;
  }
}
.toolbar .cart-btn > .btn a {
  color: var(--evo-brand-default, #a28e62);
}
.toolbar .cart-btn > .btn i {
  padding: 0;
  width: 46px;
  height: 46px;
  background-color: #e9e5e2;
  border-radius: 50%;
  margin-right: 0;
  grid-area: g1;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 991px) {
  .toolbar .cart-btn > .btn i {
    width: 32px;
    height: 32px;
  }
}
.toolbar .cart-btn > .btn i::before {
  content: "";
  background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-account2.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 19px;
  height: 21px;
  display: block;
  filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(2712%) hue-rotate(3deg) brightness(86%) contrast(83%);
}
@media only screen and (max-width: 991px) {
  .toolbar .cart-btn > .btn i::before {
    width: 12px;
  }
}
.toolbar .cart-btn > .btn > .minicart_count {
  position: static;
  font-size: 1em;
  font-weight: 600;
  padding: 0;
  grid-area: g2;
  align-self: end;
  background-color: transparent;
  color: var(--evo-text-col, #221e20);
  margin-top: 3px;
}
.toolbar .cart-btn > .btn > .minicart_count::before {
  content: "Your Basket (";
}
@media only screen and (max-width: 991px) {
  .toolbar .cart-btn > .btn > .minicart_count::before {
    content: "Basket (";
  }
}
.toolbar .cart-btn > .btn > .minicart_count::after {
  content: ")";
}
.toolbar .cart-btn > .btn > .no-price {
  line-height: 1.2;
}
.toolbar .cart-btn > .btn > .minicart_total {
  color: #939393;
  line-height: 1.5;
  grid-area: g3;
  align-self: start;
  font-size: 0.875em;
}
.toolbar .cart-btn > .btn > .minicart_total::before {
  content: "Total ";
}
.toolbar .cart-btn:hover > .btn {
  background: transparent;
}

.menu ul.catalog > li {
  padding: 0;
}
.menu ul.catalog > li:focus-within {
  background-color: #83734e;
}
.menu ul.catalog > li.has-submenu > a {
  letter-spacing: 0;
}
.menu ul.catalog > li.has-submenu > a::after {
  content: "";
  background: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-arrow-down.svg") no-repeat center;
  width: 10px;
  height: 7px;
  position: absolute;
  right: 12px;
  top: calc(50% - 3px);
  filter: brightness(0) invert(1);
}
.menu ul.catalog > li:not(.hide) {
  display: inline-block !important;
}
.menu ul.catalog > li > a {
  color: #fff;
  font-weight: 600;
  font-size: 1.042em;
  padding: 11px 32px;
  position: relative;
}
.menu ul.catalog > li > a:hover, .menu ul.catalog > li > a:focus-visible {
  background-color: transparent;
}
.menu ul.catalog > li > a:hover > a, .menu ul.catalog > li > a:focus-visible > a {
  color: var(--evo-text-col, #221e20);
}

.title-decoration {
  margin: 0 0 40px;
  font-size: var(--evo-font-size-h2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-decoration span {
  width: 7px;
  height: 7px;
  display: block;
  background-color: var(--evo-brand-default, #a28e62);
  border-radius: 50%;
  flex-shrink: 0;
}
.title-decoration span:first-child {
  margin-right: 20px;
}
.title-decoration span:last-child {
  margin-left: 20px;
}
.title-decoration::before, .title-decoration::after {
  content: "";
  width: 110px;
  height: 1px;
  display: block;
  background-color: var(--evo-brand-default, #a28e62);
}

/* stylelint-disable no-duplicate-selectors */
footer.footer {
  position: relative;
  padding: 0;
  font-size: 1em;
  overflow: hidden;
}
footer.footer::after {
  z-index: 0;
  isolation: isolate;
  content: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-foot-fade.svg");
  width: 780px;
  position: absolute;
  bottom: -220px;
  left: -140px;
  opacity: 0.03;
}
@media only screen and (max-width: 991px) {
  footer.footer::after {
    bottom: -170px;
  }
}
@media only screen and (max-width: 481px) {
  footer.footer::after {
    left: -250px;
  }
}
.footersection-1 {
  position: relative;
  z-index: 1;
  isolation: isolate;
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 768px) {
  .footersection-1 {
    padding-top: calc(80px / 2);
    padding-bottom: calc(80px / 2);
  }
}
.footersection-1 > .row {
  max-width: 1380px;
  margin-inline: auto;
  gap: 20px;
  display: flex;
  justify-content: space-between;
}
.footersection-1 > .row > div {
  width: auto;
}
.footersection-1 > .row > div .logo {
  border-bottom: hidden;
  margin-block: 17px 0;
  padding-bottom: 0;
  width: 170px;
}
.footersection-1 > .row::before, .footersection-1 > .row::after {
  display: none;
}
@media (max-width: 991px) {
  .footersection-1 > .row {
    flex-direction: column;
  }
}

footer.footer .dealerdetails_container .info {
  padding: 0;
}
footer.footer .dealerdetails_container .logo {
  border: 0;
  padding: 0;
  margin: 0 0 30px;
}
footer.footer .dealerdetails_container .logo img {
  max-width: 300px !important;
}

footer.footer .quicklinks .title {
  font-size: 1em;
  text-transform: none;
  margin: 0 0 40px;
}
footer.footer .quicklinks .title::before {
  content: "";
  height: 1px;
  width: 60px;
  background-color: var(--evo-brand-default, #a28e62);
  position: absolute;
  top: 37px;
}
footer.footer .quicklinks .title::after {
  content: "";
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: var(--evo-brand-default, #a28e62);
  position: absolute;
  top: 34px;
  left: 75px;
}
footer.footer .quicklinks ul {
  font-size: 1em;
}
footer.footer .quicklinks ul li {
  font-size: 0.875em;
  margin: 0 0 22px;
}
footer.footer .quicklinks ul li:last-child {
  margin: 0;
}
footer.footer .quicklinks ul li a {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer.footer .quicklinks ul li a:focus-within {
  color: #fff;
  text-decoration: underline;
}
footer.footer .contacts h4 {
  position: relative;
  font-size: 1em;
  text-transform: none;
  margin: 0 0 40px;
}
footer.footer .contacts h4::before {
  content: "";
  height: 1px;
  width: 60px;
  background-color: var(--evo-brand-default, #a28e62);
  position: absolute;
  top: 37px;
}
footer.footer .contacts h4::after {
  content: "";
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: var(--evo-brand-default, #a28e62);
  position: absolute;
  top: 34px;
  left: 60px;
}
footer.footer .contacts .contact-block {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 15px;
  font-size: 0.875em;
}
footer.footer .contacts .contact-block a:focus-within {
  color: #fff;
  text-decoration: underline;
}
footer.footer .contacts .contact-block .contact-icon {
  width: 20px;
  margin-right: 0;
  display: inline;
}
footer.footer .contacts .contact-block .contact-icon i {
  filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(2712%) hue-rotate(3deg) brightness(86%) contrast(83%);
}
footer.footer .contacts .opening-hours {
  margin-top: 5px;
}
footer.footer .contacts .opening-hours .hours-icon {
  background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-clock.svg");
  filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(2712%) hue-rotate(3deg) brightness(86%) contrast(83%);
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-bottom: 5px;
}
footer.footer .contacts .opening-hours .hours-description {
  display: block;
  line-height: 1.7;
}

.footer-badges {
  flex-direction: column;
  justify-content: center;
  gap: 14px;
  padding-top: 17px;
}
.footer-badges img {
  height: 76px;
}

@media only screen and (max-width: 991px) {
  footer.footer .foot-menu {
    padding-top: 0;
  }
  footer.footer .foot-menu .title {
    cursor: pointer;
    margin: 0 !important;
    background: var(--evo-brand-default, #a28e62);
    padding: 20px;
    color: #fff;
  }
  footer.footer .foot-menu .title::after {
    content: "+";
    position: relative;
    top: 0;
    left: 10px;
  }
  footer.footer .foot-menu ul {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease-out;
    padding: 0;
    margin: 0;
  }
  footer.footer .foot-menu ul li {
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
  }
  footer.footer .foot-menu ul li a {
    display: block;
    margin: 10px 0;
    padding: 20px;
    border: 1px solid #fff;
    font-size: 16px;
    background: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  footer.footer .foot-menu.open .title::after {
    content: "-";
  }
  footer.footer .foot-menu.open ul {
    height: auto;
    max-height: 500px;
    transition: max-height 1s ease-in-out !important;
  }
  footer.footer .row .footer-badges {
    gap: 10px;
  }
}
@media only screen and (max-width: 991px) {
  footer.footer .row {
    align-items: center;
  }
  footer.footer .row .menulinks_container {
    width: 100%;
  }
  footer.footer .row .contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  footer.footer .row .contacts h4 {
    width: max-content;
  }
  footer.footer .row .contacts h4::after {
    float: right;
  }
  footer.footer .row .contacts .contact-block {
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    text-align: center;
  }
}
.copyright_container {
  position: relative;
  z-index: 1;
  isolation: isolate;
  background-color: var(--evo-brand-default, #a28e62);
  padding-block: 10px;
}
.copyright_container > div {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media only screen and (min-width: 992px) {
  .copyright_container > div {
    width: 970px;
  }
}
@media only screen and (min-width: 1200px) {
  .copyright_container > div {
    width: 1180px;
  }
}
@media only screen and (min-width: 1470px) {
  .copyright_container > div {
    width: 1450px;
  }
}
.copyright_container p,
.copyright_container a {
  color: var(--evo-text-col, #221e20);
}

.hero {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin-bottom: 0 !important;
}
@media only screen and (max-width: 991px) {
  .hero .slick-autoplay-toggle-button {
    display: none;
  }
  .hero .slick-dots {
    display: none !important;
  }
}
.hero .slick-autoplay-toggle-button {
  bottom: 11px;
}
.hero .slick-dots {
  bottom: 15px;
}
.hero .slick-dots li {
  left: -20px;
  margin: 0 1px;
}
.hero .slick-dots li .slick-dot-icon {
  color: #797573;
  opacity: 1;
}
.hero .slick-dots li .slick-dot-icon:hover {
  color: var(--evo-brand-default, #a28e62);
}
.hero .slick-dots li .slick-dot-icon::before {
  margin-top: 0;
  margin-left: 0;
  font-size: 14px;
}
.hero .slick-dots li.slick-active .slick-dot-icon {
  color: #181617;
}
.hero .slick-dots li.slick-active .slick-dot-icon::before {
  margin-top: 0;
  margin-left: 0;
  font-size: 14px;
}
.hero .text-flex {
  position: relative;
  width: 770px;
  height: 540px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media only screen and (min-width: 992px) {
  .hero .text-flex {
    width: 970px;
  }
}
@media only screen and (min-width: 1200px) {
  .hero .text-flex {
    width: 1180px;
  }
}
@media only screen and (min-width: 1470px) {
  .hero .text-flex {
    width: 1450px;
  }
}
@media only screen and (max-width: 991px) {
  .hero .text-flex {
    width: 100%;
    height: 340px;
  }
}
@media only screen and (max-width: 768px) {
  .hero .text-flex {
    height: 100%;
  }
  .hero .text-flex::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 500%;
    top: 100%;
    left: 0;
    background-color: rgba(255, 255, 255, 0.83);
    backdrop-filter: blur(8px);
  }
}
@media only screen and (max-width: 768px) {
  .hero .slick-track {
    display: flex;
  }
  .hero .slick-slide {
    height: inherit !important;
  }
}
.hero .hero-text {
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  gap: 10px;
  width: 420px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.83);
  backdrop-filter: blur(8px);
}
.hero .hero-text::before {
  content: "";
  position: absolute;
  width: 500%;
  height: 100%;
  right: 100%;
  background-color: rgba(255, 255, 255, 0.83);
  backdrop-filter: blur(8px);
}
.hero .hero-text::after {
  content: "";
  position: absolute;
  width: 110px;
  height: 100%;
  left: 100%;
  background-color: rgba(255, 255, 255, 0.83);
  backdrop-filter: blur(8px);
}
@media only screen and (max-width: 991px) {
  .hero .hero-text {
    width: 100%;
    padding: 35px 10%;
  }
}
.hero .hero-text .welcome {
  transform: skewX(30deg) rotateX(90deg);
  font-size: 1.5em;
  color: var(--evo-brand-default, #a28e62);
}
.hero .hero-text h1 {
  transform: skewX(30deg) rotateX(90deg);
  line-height: 50px;
  color: var(--evo-text-col, #221e20);
}
.hero .hero-text p {
  transform: skewX(30deg) rotateX(90deg);
  font-size: 1.125em;
  line-height: 26px;
  font-weight: 500;
  margin-bottom: 20px;
  color: var(--evo-text-col, #221e20);
}
.hero .hero-text a {
  transform: rotateY(90deg);
}
.hero .slick-active .hero-text .welcome {
  transform: skewX(0deg) rotateX(0deg);
  transition: transform 0.5s ease-in 0s;
}
.hero .slick-active .hero-text h1 {
  transform: skewX(0deg) rotateX(0deg);
  transition: transform 0.5s ease-in 0.1s;
}
.hero .slick-active .hero-text p {
  transform: skewX(0deg) rotateX(0deg);
  transition: transform 0.5s ease-in 0.2s;
}
.hero .slick-active .hero-text a {
  transform: rotateY(0deg);
  transition: transform 0.35s ease-in 0.3s;
}
.hero img {
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

section .quicklinks ul {
  font-size: 1em;
}

.cat-menu-boxes .hasSubmenus {
  padding-bottom: 0;
}
@media only screen and (max-width: 768px) {
  .cat-menu-boxes .hasSubmenus .has-submenu {
    height: 100px;
    transition: all 0.35s;
  }
  .cat-menu-boxes .hasSubmenus .has-submenu::after {
    content: "+";
    position: absolute;
    left: calc(50% - 8px);
    top: 65px;
    z-index: 1;
    isolation: isolate;
    font-size: 25px;
    color: var(--evo-brand-default, #a28e62);
    transform: rotate(0deg);
    transition: all 0.35s;
  }
  .cat-menu-boxes .hasSubmenus .has-submenu.nActive {
    height: 380px;
  }
  .cat-menu-boxes .hasSubmenus .has-submenu.nActive img {
    opacity: 0.03;
  }
  .cat-menu-boxes .hasSubmenus .has-submenu.nActive::after {
    transform: rotate(135deg);
  }
}
.cat-menu-boxes .hasSubmenus .has-submenu {
  margin-bottom: 0;
}
.cat-menu-boxes .hasSubmenus .has-submenu .submenu {
  isolation: isolate;
  z-index: -1;
}
@media only screen and (min-width: 769px) {
  .cat-menu-boxes .hasSubmenus .has-submenu:hover > img, .cat-menu-boxes .hasSubmenus .has-submenu:focus-within > img {
    opacity: 0.02;
  }
  .cat-menu-boxes .hasSubmenus .has-submenu:hover .submenu, .cat-menu-boxes .hasSubmenus .has-submenu:focus-within .submenu {
    margin-top: -25px;
  }
  .cat-menu-boxes .hasSubmenus .has-submenu:hover .submenu .cat-box-icon, .cat-menu-boxes .hasSubmenus .has-submenu:focus-within .submenu .cat-box-icon {
    opacity: 0;
  }
  .cat-menu-boxes .hasSubmenus .has-submenu:hover .submenu li:nth-child(2)::after, .cat-menu-boxes .hasSubmenus .has-submenu:focus-within .submenu li:nth-child(2)::after {
    margin-bottom: 6px;
  }
  .cat-menu-boxes .hasSubmenus .has-submenu:hover .submenu li:nth-child(4), .cat-menu-boxes .hasSubmenus .has-submenu:hover .submenu li:nth-child(5), .cat-menu-boxes .hasSubmenus .has-submenu:hover .submenu li:nth-child(6), .cat-menu-boxes .hasSubmenus .has-submenu:hover .submenu li:nth-child(7), .cat-menu-boxes .hasSubmenus .has-submenu:hover .submenu li:nth-child(8), .cat-menu-boxes .hasSubmenus .has-submenu:focus-within .submenu li:nth-child(4), .cat-menu-boxes .hasSubmenus .has-submenu:focus-within .submenu li:nth-child(5), .cat-menu-boxes .hasSubmenus .has-submenu:focus-within .submenu li:nth-child(6), .cat-menu-boxes .hasSubmenus .has-submenu:focus-within .submenu li:nth-child(7), .cat-menu-boxes .hasSubmenus .has-submenu:focus-within .submenu li:nth-child(8) {
    margin-bottom: 6px;
  }
}
.cat-menu-boxes > ul {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1px;
  overflow: hidden;
}
@media only screen and (max-width: 1280px) {
  .cat-menu-boxes > ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 768px) {
  .cat-menu-boxes > ul {
    display: grid;
    grid-template-columns: 1fr;
  }
}
.cat-menu-boxes > ul > li {
  position: relative;
  height: 300px;
  background-color: #221e20;
  flex-basis: 16.5%;
}
.cat-menu-boxes > ul > li > img {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.15;
  transition: opacity 0.35s;
  object-fit: cover;
}
.cat-menu-boxes .submenu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-left: 0 !important;
  align-items: center;
  margin-top: 90px;
  transition: 0.35s;
}
.cat-menu-boxes .submenu .cat-box-icon {
  display: flex;
  justify-content: center;
  opacity: 1;
  transition: 0.35s;
}
.cat-menu-boxes .submenu .cat-box-icon a {
  height: 35px;
  width: 35px;
  filter: brightness(0) invert(100%);
}
.cat-menu-boxes .submenu li a {
  color: #fff;
  font-size: 1em;
}
.cat-menu-boxes .submenu li a:hover, .cat-menu-boxes .submenu li a:focus-within {
  text-decoration: underline;
}
.cat-menu-boxes .submenu li:nth-child(2) a {
  position: relative;
  width: 160px;
  font-weight: 700;
  font-size: 1.375em;
  line-height: normal;
  color: #fff;
}
.cat-menu-boxes .submenu li:nth-child(2)::after {
  content: "";
  display: block;
  margin-inline: auto;
  width: 53px;
  height: 3px;
  background-color: var(--evo-brand-default, #a28e62);
  margin-bottom: 70px;
  transition: 0.35s;
}
.cat-menu-boxes .submenu li:nth-child(4), .cat-menu-boxes .submenu li:nth-child(5), .cat-menu-boxes .submenu li:nth-child(6), .cat-menu-boxes .submenu li:nth-child(7), .cat-menu-boxes .submenu li:nth-child(8) {
  margin-bottom: 30px;
}
.cat-menu-boxes .submenu li:nth-child(4) {
  transition: 0.35s ease-in-out 0.1s;
}
.cat-menu-boxes .submenu li:nth-child(5) {
  transition: 0.35s ease-in-out 0.2s;
}
.cat-menu-boxes .submenu li:nth-child(6) {
  transition: 0.35s ease-in-out 0.3s;
}
.cat-menu-boxes .submenu li:nth-child(7) {
  transition: 0.35s ease-in-out 0.4s;
}
.cat-menu-boxes .submenu li:nth-child(8) {
  transition: 0.35s ease-in-out 0.5s;
}
.cat-menu-boxes .submenu li:last-child a {
  font-weight: 600;
  color: var(--evo-brand-default, #a28e62);
}
@media only screen and (max-width: 768px) {
  .cat-menu-boxes .submenu {
    margin-top: 15px;
  }
  .cat-menu-boxes .submenu li:first-child {
    display: none;
  }
  .cat-menu-boxes .submenu li:nth-child(2) {
    width: max-content;
  }
  .cat-menu-boxes .submenu li:nth-child(2) a {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 50px;
    font-size: 1em;
  }
  .cat-menu-boxes .submenu li:nth-child(2)::after {
    display: none;
  }
}

.services-hero {
  position: relative;
  overflow: hidden;
  background-color: rgba(34, 30, 32, 0.93);
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 768px) {
  .services-hero {
    padding-top: calc(80px / 2);
    padding-bottom: calc(80px / 2);
  }
}
.services-hero img {
  object-fit: cover;
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
@media only screen and (max-width: 769px) {
  .services-hero .services-text::after {
    content: "";
    text-align: center;
    position: absolute;
    width: 400%;
    height: 80%;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(180deg, rgba(34, 30, 32, 0), rgba(34, 30, 32, 0), #221e20, #221e20);
  }
}
.services-hero .services-text h2 {
  margin: 0 0 20px;
  color: #fff;
}
.services-hero .services-text p {
  font-weight: 600;
  margin-bottom: 0;
  color: #fff;
}
.services-hero .services-text .divider {
  margin: 25px 0;
  height: 1px;
  width: 100%;
  background-color: var(--evo-brand-default, #a28e62);
  position: relative;
}
.services-hero .services-text .divider::before, .services-hero .services-text .divider::after {
  content: "";
  width: 7px;
  height: 7px;
  background-color: var(--evo-brand-default, #a28e62);
  border-radius: 50%;
  position: absolute;
  top: -3px;
}
.services-hero .services-text .divider::before {
  left: 0;
}
.services-hero .services-text .divider::after {
  right: 0;
}
@media only screen and (max-width: 769px) {
  .services-hero .services-text .read-more-grid {
    display: grid;
    grid-template-rows: 0.4fr;
    transition: grid-template-rows 0.5s;
  }
}
.services-hero .services-text .read-more-grid ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 17px;
  overflow: hidden;
  margin-top: 40px;
}
@media only screen and (max-width: 1469px) {
  .services-hero .services-text .read-more-grid ul {
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
  }
}
@media only screen and (max-width: 991px) {
  .services-hero .services-text .read-more-grid ul {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 481px) {
  .services-hero .services-text .read-more-grid ul {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 769px) {
  .services-hero .services-text .read-more-grid ul {
    max-height: auto;
  }
}
.services-hero .services-text .read-more-grid ul li {
  gap: 15px 30px;
  width: 100%;
  color: #fff;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.services-hero .services-text .read-more-grid ul li:empty {
  display: none;
}
.services-hero .services-text .read-more-grid ul li::before {
  content: "\f00c";
  font-family: FontAwesome;
  font-weight: 400;
  font-size: 25px;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  background-color: #fff;
  color: var(--evo-brand-default, #a28e62);
  flex-shrink: 0;
  transform: rotateY(0deg);
  transition: transform 1s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.services-hero .services-text .read-more-grid ul li:hover::before {
  transform: rotateY(360deg);
}
.services-hero .services-text .module-button {
  margin-top: 40px;
  gap: 10px;
}
@media only screen and (max-width: 1469px) {
  .services-hero .services-text .module-button {
    margin-bottom: 0;
  }
  .services-hero .services-text .module-button .nButton {
    z-index: 2;
    padding: 25px;
  }
}
.services-hero .services-text .module-button button {
  position: relative;
  display: none;
}
.services-hero .services-text .module-button button::before {
  content: "Expand";
}
.services-hero .services-text .module-button button::after {
  content: "\f063";
  position: absolute;
  font-family: FontAwesome;
  font-weight: 400;
  font-size: 25px;
  width: 42px;
  height: 42px;
  bottom: -21px;
  left: 32%;
  border-radius: 50%;
  background-color: #fff;
  color: var(--evo-brand-default, #a28e62);
  flex-shrink: 0;
  transition: transform 0.5s, color 0.35s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.services-hero .services-text .module-button button:hover::after {
  background-color: #a28e62;
  color: #fff;
}
@media only screen and (max-width: 769px) {
  .services-hero .services-text .module-button button {
    display: block;
  }
}
.services-hero .services-text.nActive::after {
  background: linear-gradient(180deg, rgba(34, 30, 32, 0), rgba(34, 30, 32, 0), rgba(255, 0, 128, 0));
}
.services-hero .services-text.nActive .read-more-grid {
  grid-template-rows: 1fr;
}
.services-hero .services-text.nActive button::before {
  content: "Collapse";
}
.services-hero .services-text.nActive button::after {
  transform: rotate(180deg);
}

.custom-merchandising {
  margin: 0;
}
.custom-merchandising h3 {
  margin: 0 0 40px;
  font-size: var(--evo-font-size-h2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-merchandising h3 span {
  width: 7px;
  height: 7px;
  display: block;
  background-color: var(--evo-brand-default, #a28e62);
  border-radius: 50%;
  flex-shrink: 0;
}
.custom-merchandising h3 span:first-child {
  margin-right: 20px;
}
.custom-merchandising h3 span:last-child {
  margin-left: 20px;
}
.custom-merchandising h3::before, .custom-merchandising h3::after {
  content: "";
  width: 110px;
  height: 1px;
  display: block;
  background-color: var(--evo-brand-default, #a28e62);
}
.custom-merchandising .slick-slider {
  padding: 0;
  margin: 0;
}
.custom-merchandising .slick-list {
  margin: 0 -12px;
}
.custom-merchandising .slick-slide {
  margin: 0 12px;
}
.custom-merchandising .slick-arrow {
  top: 105px;
}
.custom-merchandising.catalog-grid .tileparent {
  padding: 0;
}
.custom-merchandising.catalog-grid .tile {
  padding: 0;
  margin: 0;
  max-width: none;
}
.custom-merchandising.catalog-grid .tile .brand-label,
.custom-merchandising.catalog-grid .tile .uom,
.custom-merchandising.catalog-grid .tile .indicators-grid,
.custom-merchandising.catalog-grid .tile .product-compare-action,
.custom-merchandising.catalog-grid .tile .addtoquicklist-wrapper,
.custom-merchandising.catalog-grid .tile .price-label-inc-vat {
  display: none !important;
}
.custom-merchandising.catalog-grid .tile a:focus-visible .imgthumbnail {
  opacity: 0.5;
}
.custom-merchandising.catalog-grid .tile .imgthumbnail {
  height: 208px;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 5px;
  margin: 0 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-merchandising.catalog-grid .tile .imgthumbnail img {
  mix-blend-mode: multiply;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.custom-merchandising.catalog-grid .tile .product-name {
  margin: 0 0 20px;
  font-size: 1em;
  line-height: 1.5;
}
.custom-merchandising.catalog-grid .tile .product-name a {
  color: #494949;
}
.custom-merchandising.catalog-grid .tile .product-name a:hover, .custom-merchandising.catalog-grid .tile .product-name a:focus-visible {
  color: var(--evo-brand-default, #a28e62);
}
.custom-merchandising.catalog-grid .tile .product-sku,
.custom-merchandising.catalog-grid .tile .product-category {
  font-size: 0.875em;
  color: #939393;
}
.custom-merchandising.catalog-grid .tile .product-sku strong,
.custom-merchandising.catalog-grid .tile .product-category strong {
  font-weight: 400;
}
.custom-merchandising.catalog-grid .tile .product-sku strong::after,
.custom-merchandising.catalog-grid .tile .product-category strong::after {
  content: ":";
}
.custom-merchandising.catalog-grid .tile .product-sku a,
.custom-merchandising.catalog-grid .tile .product-category a {
  color: #939393;
}
.custom-merchandising.catalog-grid .tile .product-sku a:hover, .custom-merchandising.catalog-grid .tile .product-sku a:focus-visible,
.custom-merchandising.catalog-grid .tile .product-category a:hover,
.custom-merchandising.catalog-grid .tile .product-category a:focus-visible {
  color: var(--evo-brand-default, #a28e62);
}
.custom-merchandising.catalog-grid .tile .price-label {
  color: #221e20;
  font-weight: 600;
  font-size: 1.5em;
  margin-top: 15px;
}
.custom-merchandising.catalog-grid .tile .footer .footer-wrapper {
  min-height: 0;
}
.custom-merchandising.catalog-grid .tile .buttons.group {
  gap: 5px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom-merchandising.catalog-grid .tile .buttons.group::after {
  display: none;
}
.custom-merchandising.catalog-grid .tile .buttons.group input {
  height: 42px;
  width: 45px;
  margin: 0;
  border-radius: 22px;
  font-size: 0.875em;
}
.custom-merchandising.catalog-grid .tile .buttons.group .btn {
  margin: 0;
  flex-grow: 1;
  min-height: 42px;
  border-radius: 22px;
  font-weight: 700;
}
.custom-merchandising.catalog-grid .tile .buttons.group .btn::before {
  content: "";
  background: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-basket2.svg") no-repeat center;
  width: 14px;
  height: 13px;
  display: inline-block;
  filter: brightness(0) invert(1);
  margin-right: 10px;
}
.custom-merchandising.catalog-grid .tile .buttons.group .btn:hover, .custom-merchandising.catalog-grid .tile .buttons.group .btn:focus-visible {
  background-color: #221e20;
}
.custom-merchandising.catalog-grid .tile .buttons.group .btn:hover::before, .custom-merchandising.catalog-grid .tile .buttons.group .btn:focus-visible::before {
  filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(2712%) hue-rotate(3deg) brightness(86%) contrast(83%);
}

.who-are-we {
  flex-direction: row;
  justify-content: center;
}
.who-are-we .container > .nFlex {
  flex-wrap: wrap;
  margin-block: 10px;
}
@media only screen and (max-width: 1469px) {
  .who-are-we .container > .nFlex {
    justify-content: center;
  }
}
.who-are-we .text-wrapper {
  position: relative;
  left: 0;
  top: 0;
}
@media only screen and (max-width: 1469px) {
  .who-are-we .text-wrapper {
    width: 90%;
  }
}
.who-are-we .text-wrapper .text-section {
  width: 600px;
  flex-direction: column;
  align-items: baseline;
  gap: 5px;
}
@media only screen and (max-width: 1469px) {
  .who-are-we .text-wrapper .text-section {
    width: 100%;
  }
}
.who-are-we .text-wrapper .text-section h2 {
  color: var(--evo-brand-default, #a28e62);
  font-size: 1.125em;
  margin-bottom: 15px;
}
.who-are-we .text-wrapper .text-section h1 {
  font-size: 1.875em;
  margin-bottom: 15px;
}
.who-are-we .text-wrapper .text-section p {
  margin-bottom: 15px;
}
.who-are-we .text-wrapper .text-section p:first-of-type {
  font-weight: 500;
}
.who-are-we .text-wrapper .text-section p:last-of-type {
  padding-bottom: 15px;
}
@media only screen and (max-width: 768px) {
  .who-are-we .text-wrapper .text-section a {
    margin-inline: auto;
  }
}
.who-are-we .image-section {
  width: 667px;
  position: relative;
  flex-direction: column;
}
.who-are-we .image-section img {
  min-width: 667px;
}
@media only screen and (max-width: 768px) {
  .who-are-we .image-section img {
    min-width: 100%;
  }
}
@media only screen and (max-width: 477px) {
  .who-are-we .image-section img {
    display: none;
  }
}
.who-are-we .image-section ul {
  position: absolute;
  bottom: 55px;
  display: flex;
  justify-content: space-around;
  gap: 55px;
  color: var(--evo-text-col, #221e20);
  background-color: #fafafa;
  padding-block: 20px;
  font-weight: 400;
}
.who-are-we .image-section ul span {
  display: block;
  font-weight: 700;
  font-size: 1.5em;
}
.who-are-we .image-section ul span::after {
  content: "+";
  color: var(--evo-brand-default, #a28e62);
}
.who-are-we .image-section ul li {
  flex-shrink: 0;
}
@media only screen and (max-width: 768px) {
  .who-are-we .image-section ul {
    flex-direction: column;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
}
@media only screen and (max-width: 564px) {
  .who-are-we .image-section ul {
    bottom: 25px;
    gap: 10px;
  }
}
@media only screen and (max-width: 477px) {
  .who-are-we .image-section ul {
    position: relative;
    bottom: 0;
    margin-block: 10px;
    width: fit-content;
    justify-content: center;
    align-items: center;
  }
  .who-are-we .image-section ul li {
    width: max-content;
    text-align: center;
  }
  .who-are-we .image-section ul li span {
    height: 25px;
  }
}

.custom-brands {
  padding: 0;
  margin: 0 !important;
  background-color: #000;
  isolation: isolate;
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 768px) {
  .custom-brands {
    padding-top: calc(80px / 2);
    padding-bottom: calc(80px / 2);
  }
}
.custom-brands::before {
  content: "";
  background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-brands-bg.jpg");
  background-size: cover;
  background-position: center;
  z-index: -1;
  opacity: 0.12;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.custom-brands h3 {
  color: #fff;
  font-size: var(--evo-font-size-h2);
}
.custom-brands .slick-slider {
  padding: 0;
  margin: 0;
}
.custom-brands .slick-arrow {
  top: 52px;
}
.custom-brands .slick-list {
  margin: 0 -19px;
}
@media only screen and (max-width: 1280px) {
  .custom-brands .slick-list {
    margin: 0 -10px;
  }
}
.custom-brands .slick-slide {
  margin: 0 19px;
}
@media only screen and (max-width: 1280px) {
  .custom-brands .slick-slide {
    margin: 0 -10px;
  }
}
.custom-brands .slick-slide a {
  display: block;
  width: 100%;
  height: 100%;
  height: 105px;
  border-radius: 10px;
  background-color: #fff;
  padding: 10px;
  transition: background 0.35s;
}
@media only screen and (max-width: 1280px) {
  .custom-brands .slick-slide a {
    border-radius: 0;
    padding: 10px 20px 10px 0;
  }
}
.custom-brands .slick-slide a .text-center {
  height: 100%;
}
.custom-brands .slick-slide a .text-center > div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-brands .slick-slide a img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
  mix-blend-mode: multiply;
}
.custom-brands .slick-slide a:hover {
  background-color: var(--evo-brand-default, #a28e62);
}

.module-projects-news .container > .nFlex {
  align-items: stretch;
}

.module-projects {
  width: 49%;
}
.module-projects .slick-arrow {
  top: 583px;
}

.module-news {
  width: 49%;
  max-height: 800px;
  overflow: auto;
}

.tile-blog {
  border-radius: 10px;
  overflow: hidden;
}
.tile-img {
  height: 445px;
  width: 100%;
  display: block;
  position: relative;
  transition: opacity 0.35s;
}
.tile-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tile-category {
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
  left: 0;
  padding: 15px 22px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.83) 0%, rgba(0, 0, 0, 0) 100%);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.tile-category img {
  margin-right: 15px;
}
.tile-category span {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.875em;
  color: #fff;
}

.tile-img:hover, .tile-img:focus-visible {
  opacity: 0.7;
}

.tile-text {
  background-color: #fff;
  padding: 40px;
  height: 360px;
  overflow: auto;
}
.tile-title {
  margin: 0 0 15px;
}

.tile-excerpt p {
  margin: 0 0 15px;
  line-height: 1.6;
}

.tile-readmore {
  display: inline-block;
  font-weight: 700;
}
.tile-readmore img {
  filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(2712%) hue-rotate(3deg) brightness(86%) contrast(83%);
  margin-left: 10px;
  display: inline-block;
}
.tile-readmore:hover, .tile-readmore:focus-visible {
  text-decoration: underline;
}

.tile-grid .tile-text p:first-child {
  font-weight: 500;
}
.tile-grid .tile-title {
  font-size: 1.25em;
}

.tile-list {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.tile-list .tile-img {
  width: 42%;
  height: 300px;
}
.tile-list .tile-text {
  padding: 25px;
  width: 58%;
  height: 300px;
  overflow: auto;
  font-size: 0.875em;
}
.tile-list + .tile-list {
  margin-top: 20px;
}

.ex-name-testimonials .listgrid {
  display: none;
}
.ex-name-testimonials .blog-testimonials .row {
  margin-top: 40px;
  margin-bottom: 40px;
}

.blog-testimonials {
  margin-bottom: 0 !important;
}
.blog-testimonials.blog-posts-grid > h4 {
  border: 0;
  padding: 0;
}
.blog-testimonials .slick-slider {
  margin-top: 20px;
}
.blog-testimonials .slick-list {
  margin: 0 -19px;
}
.blog-testimonials .slick-slide {
  margin: 0 19px;
  position: relative;
}
.blog-testimonials .slick-slide .archive-testimonial {
  margin: 19px 0;
}
.blog-testimonials .slick-active + .slick-active::before {
  content: "";
  width: calc(100% + 56px);
  height: calc(100% + 38px);
  left: -28px;
  top: -19px;
  position: absolute;
  background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-review-shape.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1280px) {
  .blog-testimonials .slick-active + .slick-active::before {
    display: none;
  }
}
.blog-testimonials .slick-active + .slick-active ~ .slick-active::before {
  display: none;
}
.blog-testimonials .blog-posts-grid-view {
  margin-inline: 15px;
}
.blog-testimonials .blog-posts-grid-view:not(.slick-slider) {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  gap: 38px;
}
@media only screen and (max-width: 1280px) {
  .blog-testimonials .blog-posts-grid-view:not(.slick-slider) {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 991px) {
  .blog-testimonials .blog-posts-grid-view:not(.slick-slider) {
    grid-template-columns: 1fr;
  }
}
.blog-testimonials .blog-posts-grid-view::before, .blog-testimonials .blog-posts-grid-view::after {
  display: none;
}
.blog-testimonials .blog-posts-grid-view .blog-post-wrapper {
  width: 100%;
  padding: 0;
}
.archive-testimonial {
  background-color: #e9e5e2;
  border-radius: 10px;
  padding: 40px;
  height: 400px;
  overflow: auto;
}
.testimonial-icons {
  margin: 0 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.testimonial-stars {
  gap: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.testimonial-stars .star {
  width: 29px;
  height: 29px;
  background-color: #f6f6f6;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testimonial-stars .star img {
  filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(2712%) hue-rotate(3deg) brightness(86%) contrast(83%);
}

.testimonial-quote {
  filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(2712%) hue-rotate(3deg) brightness(86%) contrast(83%);
}

.testimonial-excerpt p {
  margin: 0 0 15px;
  line-height: 1.5;
}

.archive-testimonial h3 {
  font-weight: 600;
  margin: 0 0 20px;
}

.hero-banner {
  position: relative;
  background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-hero-banner.jpg");
  background-size: cover;
  background-position: center center;
  z-index: 0;
  isolation: isolate;
}
.hero-banner::after {
  content: "";
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 50%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.79;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 242px;
}
.hero-banner .header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 242px;
  z-index: 1;
}
.hero-banner .header h1 {
  font-size: 1.875em;
}

.service-portal {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  z-index: 1;
  text-align: center;
  width: 950%;
  max-width: 392px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .service-portal {
    display: none;
  }
}
.service-portal > h2 {
  font-size: 1em;
  background-color: #221e20;
  background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/portal-header.jpg");
  background-size: cover;
  background-position: center;
  margin: 0;
  padding: 10px;
  transition: all 0.35s;
}
.service-portal > h2 i {
  margin-left: 10px;
  transition: all 0.35s;
}
.service-portal > h2:hover {
  opacity: 0.7;
}
.service-portal-dropdown {
  display: none;
}
.service-portal-dropdown-text {
  padding: 25px 35px;
}
.service-portal-dropdown h2 {
  font-size: 16px;
  margin: 0 0 15px;
}
.service-portal-dropdown h2::after {
  content: "";
  display: block;
  width: 51px;
  height: 3px;
  background-color: #a28e62;
  margin: 15px auto;
}
.service-portal-dropdown p {
  font-size: 14px;
  margin: 0;
  color: #656565;
  font-weight: 500;
}
.service-portal-dropdown-button {
  padding: 60px 35px;
  background-color: #221e20;
  background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/portal-bg.jpg");
  background-size: cover;
  background-position: center;
}
.service-portal-dropdown .nButton {
  font-size: 14px;
}
.service-portal.nActive h2 i {
  transform: rotate(90deg);
}
.service-portal.nActive .service-portal-dropdown {
  display: block;
}

.about-us-wrapper {
  background-color: #e9e5e2;
  padding-bottom: 120px;
}

.about-us-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 1070px) {
  .about-us-content {
    flex-direction: column;
    justify-content: center;
  }
}
.about-us-content .text-section {
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  width: 50%;
}
@media only screen and (max-width: 1070px) {
  .about-us-content .text-section {
    width: 100%;
    padding-bottom: 20px;
  }
}
.about-us-content .text-section h3 {
  color: var(--evo-brand-default, #a28e62);
  margin-bottom: 15px;
}
.about-us-content .text-section h1 {
  color: #221e20;
  font-size: 1.875em;
  margin: 0;
}
.about-us-content .text-section p {
  margin: 0;
}
.about-us-content .image-section {
  height: auto;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: 20px;
  overflow: hidden;
}
.about-us-content .image-section .lower-images {
  gap: 20px;
  overflow: hidden;
}
.about-us-content .image-section img {
  width: 100%;
  border-radius: 5px;
  transition: 0.35s ease;
}
.about-us-content .image-section img:hover {
  scale: 1.05;
}

.solution-cards {
  position: relative;
  top: -100px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
@media only screen and (max-width: 1470px) {
  .solution-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 700px) {
  .solution-cards {
    grid-template-columns: 1fr;
  }
}
.solution-cards .first-card {
  height: 350px;
  position: relative;
  border-radius: 5px;
  background-color: #221e20;
  overflow: hidden;
}
.solution-cards .first-card:hover img {
  scale: 1.2;
  transform: rotate(5deg);
  filter: brightness(0) invert(1) blur(5px);
}
.solution-cards .first-card img {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.15;
  object-fit: contain;
  filter: brightness(0) invert(1);
  padding: 10px 40px 40px;
  rotate: -90deg;
  transition: 0.35s ease;
}
.solution-cards .first-card .card-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 30px 0 40px;
}
@media only screen and (max-width: 400px) {
  .solution-cards .first-card .card-text {
    padding: 20px 15px 0 20px;
  }
}
.solution-cards .first-card .card-text h3 {
  color: #fff;
  margin-bottom: 0;
  font-size: 1.625em;
  font-weight: 700;
}
.solution-cards .first-card .card-text p {
  color: #fff;
  margin-top: 15px;
  line-height: 24px;
}
.solution-cards .card {
  height: 350px;
  position: relative;
  border-radius: 5px;
  background-color: #221e20;
  overflow: hidden;
}
.solution-cards .card:hover img {
  scale: 1.2;
  transform: rotate(5deg);
  filter: brightness(0) invert(1) blur(5px);
}
.solution-cards .card:nth-child(6) img {
  rotate: -90deg;
}
.solution-cards .card img {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.15;
  object-fit: contain;
  padding: 40px;
  filter: brightness(0) invert(1);
  transition: 0.35s ease;
}
.solution-cards .card .card-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.solution-cards .card .card-text h3 {
  font-size: 1.375em;
  color: #fff;
  margin: 15px 0;
}
.solution-cards .card .card-text h3::after {
  content: "";
  display: block;
  width: 53px;
  height: 3px;
  background-color: var(--evo-brand-default, #a28e62);
  margin: 25px auto 5px;
}
.solution-cards .card .card-text p {
  text-align: center;
  color: #fff;
}

.card-wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 1350px) {
  .card-wrapper {
    flex-direction: column;
    gap: 20px;
  }
}
.card-wrapper .card {
  background-color: #e9e5e2;
  border-radius: 9px;
  padding: 60px;
  width: 48%;
}
@media only screen and (max-width: 1305px) {
  .card-wrapper .card {
    width: 100%;
  }
}
.card-wrapper .card h4 {
  font-size: 1.375em;
  font-weight: 700;
  margin-bottom: 20px;
}
.card-wrapper .card p {
  margin-bottom: 12px;
}
.card-wrapper .card .contact-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
@media only screen and (max-width: 672px) {
  .card-wrapper .card .contact-links {
    flex-direction: column;
    gap: 20px;
  }
}
.card-wrapper .card .contact-links * {
  color: #221e20;
  transition: 0.35s ease;
}
.card-wrapper .card .contact-links * i {
  color: var(--evo-brand-default, #a28e62);
}
.card-wrapper .card .contact-links *:hover {
  color: var(--evo-brand-default, #a28e62);
  text-decoration: none;
}
.card-wrapper .sales-team .contact-description p {
  cursor: pointer;
  margin-bottom: 0;
}
.card-wrapper .sales-team .contact-email,
.card-wrapper .sales-team .contact-phone {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.card-wrapper .sales-team .contact-email i::before,
.card-wrapper .sales-team .contact-phone i::before {
  font-size: 20px;
}
.card-wrapper .sales-team .contact-chat::before {
  content: "\f27a";
  font-family: FontAwesome;
  display: inline-block;
  font-size: 20px;
  margin-right: 20px;
  filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(2712%) hue-rotate(3deg) brightness(86%) contrast(83%);
}
.card-wrapper .service-team .contact-links {
  text-align: center;
}
.card-wrapper .service-team .contact-links a::after {
  content: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-arrow-right.svg");
  display: inline-block;
  width: 22px;
  height: 13px;
  margin-left: 20px;
  filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(2712%) hue-rotate(3deg) brightness(86%) contrast(83%);
}

.icon-list {
  margin: 50px 0;
}
.icon-list::before {
  display: none;
}
.icon-list ul {
  flex-direction: row;
  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 768px) {
  .icon-list ul {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
}
.icon-list ul li {
  gap: 15px;
  list-style: none;
  margin: 0;
}
.icon-list ul li svg {
  display: inline-block;
  height: 50px;
  stroke: var(--evo-brand-default, #a28e62);
}
.icon-list ul li p {
  color: var(--evo-text-col, #221e20);
  margin: 0;
}

.post-content p:first-of-type {
  font-weight: 600;
}

.widget-blogs-search .searchlist {
  margin: 50px 0;
}
.widget-blogs-search .searchlist h4 {
  margin: 0 0 15px;
}
.widget-blogs-search .searchlist .relsearch .form-control {
  height: 55px;
  border-radius: 27.5px;
  border: 1px solid #e9e5e2;
  padding: 25px 20px;
  margin: 0;
}

.blogsearch_container .search-form button[type=submit] {
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 55px;
  width: 55px;
}
.blogsearch_container .search-form button[type=submit] i {
  background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-search.svg");
  height: 15px;
  width: 15px;
  background-size: contain;
}
.blogsearch_container .search-form button[type=submit] i::before {
  display: none;
}
.blogsearch_container .search-form button[type=submit]:hover {
  opacity: 0.4;
}

.case-study-cat h4 {
  font-weight: 700;
  margin: 0 0 15px;
}
.case-study-cat ul {
  padding: 0;
}
.case-study-cat li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  padding: 10px 0;
  margin: 0;
}
.case-study-cat li::after {
  content: "";
  position: absolute;
  bottom: 0;
  display: block;
  height: 1px;
  width: 100%;
  background: #dedede;
}
.case-study-cat li:last-of-type {
  margin: 0 0 40px;
}
.case-study-cat li:last-of-type::after {
  display: none;
}
.case-study-cat li a {
  color: var(--evo-text-col, #221e20);
}
.case-study-cat li a:hover {
  color: var(--evo-brand-default, #a28e62);
}
.case-study-cat li p {
  margin: 0;
}
.case-study-cat li p::before {
  content: "(";
}
.case-study-cat li p::after {
  content: ")";
}

.case-study-title {
  font-weight: 700;
  margin: 0 0 15px;
}

.blog-posts-list-view .blog-post-wrapper .tile {
  padding: 0;
  margin: 0 0 15px;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 991px) {
  .blog-posts-list-view .blog-post-wrapper .tile {
    max-width: fit-content;
  }
}
.blog-posts-list-view .blog-post-wrapper .tile a {
  transition: 0.35s ease;
}
.blog-posts-list-view .blog-post-wrapper .tile a:hover {
  opacity: 0.4;
}
.blog-posts-list-view .blog-post-wrapper .tile .post-image {
  width: 128px;
  height: 128px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 9px;
}
.blog-posts-list-view .blog-post-wrapper .tile .footer {
  width: 55%;
  min-height: auto;
}
@media only screen and (max-width: 991px) {
  .blog-posts-list-view .blog-post-wrapper .tile .footer {
    width: 100%;
  }
}
.blog-posts-list-view .blog-post-wrapper .tile .footer .footer-wrapper {
  min-height: auto;
}
.blog-posts-list-view .blog-post-wrapper .tile .footer .footer-wrapper .post-excerpt {
  max-height: 120px;
  margin: 0;
  overflow-y: auto;
  font-size: 0.875em;
}

.contact-header {
  padding: 90px 0 40px;
}
@media only screen and (max-width: 1366px) {
  .contact-header {
    padding: 90px 15px 40px;
  }
}
.contact-header::after {
  content: "";
  height: 1px;
  width: 100%;
  background-color: var(--evo-brand-default, #a28e62);
  display: block;
  margin: 40px 0 0;
}
@media only screen and (max-width: 768px) {
  .contact-header > div {
    flex-direction: column;
    gap: 20px;
  }
  .contact-header > div .contact-title {
    width: 100%;
    text-align: center;
  }
  .contact-header > div .contact-title p {
    max-width: 100%;
  }
  .contact-header > div .contact-info {
    width: 100%;
    justify-content: space-between;
  }
  .contact-header > div .contact-info .contact-address {
    width: 100%;
  }
  .contact-header > div .contact-info .contact-address p {
    padding: 0;
  }
}
@media only screen and (max-width: 481px) {
  .contact-header > div .contact-info {
    flex-direction: column;
    gap: 20px;
  }
}
.contact-header .contact-title {
  width: 50%;
}
.contact-header .contact-title h2 {
  margin: 0 0 20px;
}
.contact-header .contact-title p {
  max-width: 500px;
  margin: 0;
}
.contact-header .contact-info {
  width: 55%;
  display: flex;
  gap: 10px;
}
.contact-header .contact-info .contact-address {
  width: 50%;
  display: flex;
}
.contact-header .contact-info .contact-address .contact-icon {
  width: 30px;
  margin-right: 0;
  display: inline;
}
.contact-header .contact-info .contact-address .contact-icon i {
  font-size: 25px;
  filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(2712%) hue-rotate(3deg) brightness(86%) contrast(83%);
}
.contact-header .contact-info .contact-address p {
  padding: 0 50px 0 0;
  font-weight: 600;
  margin: 0;
  font-size: 1em;
}
.contact-header .contact-details {
  min-width: 211px;
}
.contact-header .contact-details .contact-block {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.contact-header .contact-details .contact-block p,
.contact-header .contact-details .contact-block .hours-description {
  font-weight: 600;
  margin: 0;
}
.contact-header .contact-details .contact-block a {
  color: var(--evo-text-col, #221e20);
}
.contact-header .contact-details .contact-block a:hover, .contact-header .contact-details .contact-block a:focus-within {
  color: var(--evo-brand-default, #a28e62);
  text-decoration: none;
}
.contact-header .contact-details .contact-block .contact-icon {
  margin-right: 0;
  display: inline;
}
.contact-header .contact-details .contact-block .contact-icon i::before {
  font-size: 18px;
  filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(2712%) hue-rotate(3deg) brightness(86%) contrast(83%);
}
.contact-header .contact-details .opening-hours .hours-icon {
  background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-clock.svg");
  filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(2712%) hue-rotate(3deg) brightness(86%) contrast(83%);
  display: inline-block;
  height: 15px;
  width: 15px;
  background-repeat: no-repeat;
}
.contact-header .contact-details .opening-hours .hours-description {
  display: block;
  line-height: 1.7;
}

.contact-form::after {
  content: "";
  height: 3px;
  width: 100%;
  background-color: #221e20;
  display: block;
}
.contact-form .container {
  padding: 0;
}

.form-section {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 0 60px;
}
@media only screen and (max-width: 1366px) {
  .form-section {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 768px) {
  .form-section {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 481px) {
  .form-section {
    grid-template-columns: 1fr;
  }
}
.form-section .form-group {
  margin: 0;
  padding: 0 35px 25px 0;
}
@media only screen and (max-width: 481px) {
  .form-section .form-group {
    padding: 0 0 20px;
  }
  .form-section .form-group label {
    margin: 0 0 5px;
  }
  .form-section .form-group input,
  .form-section .form-group select {
    height: 40px !important;
  }
}
.form-section .form-group label span {
  color: #ac998a;
}
.form-section .form-group input,
.form-section .form-group select,
.form-section .form-group textarea {
  height: 60px;
  border-radius: 10px;
  padding: 6px 25px;
}
.form-section .form-group.additional-info {
  width: 100%;
  grid-column: 1/span 2;
}
@media only screen and (max-width: 481px) {
  .form-section .form-group.additional-info {
    grid-column: 1;
  }
}
.form-section .form-group.additional-info textarea {
  height: 160px;
  overflow: auto;
}
.form-section .g-recaptcha {
  margin: 34px 0 0;
  grid-column: 1/span 2;
}
@media only screen and (max-width: 481px) {
  .form-section .g-recaptcha {
    grid-column: 1;
  }
}

.location-map iframe {
  width: 100%;
  height: 400px;
}

.services-info {
  margin: 60px 0 20px;
}
.services-info p {
  font-weight: 400 !important;
}

.ex-name-servicesweoffer .readmore {
  display: none !important;
}
@media only screen and (max-width: 769px) {
  .ex-name-servicesweoffer .read-more-grid {
    grid-template-rows: 1fr !important;
  }
}
.ex-name-servicesweoffer .services-hero .services-text::after {
  display: none;
}

.loler-puwer h3,
.loler-puwer li {
  color: #fff;
}
.loler-puwer h3 {
  margin: 5px 0 10px;
}
.loler-puwer p {
  font-weight: 400 !important;
}

.services-page {
  padding: 12px 80px;
  font-weight: 400 !important;
}

.services-form-title {
  padding: 70px 15px 40px;
}

.ex-name-servicesweoffer .contact-form::after {
  display: none;
}
.ex-name-servicesweoffer .form-section {
  padding: 0 15px;
}

.ex-name-testimonials .category-name {
  display: none;
}
.ex-name-testimonials .itemsfound {
  display: none !important;
}
.ex-name-testimonials .listgrid {
  display: block;
  position: absolute;
  right: 20px;
  top: 50px;
}
.ex-name-testimonials .case-study-cat {
  margin: 0 0 50px;
}
.ex-name-testimonials .case-study-cat li:nth-child(1),
.ex-name-testimonials .case-study-cat li:nth-child(4),
.ex-name-testimonials .case-study-cat li:nth-child(5),
.ex-name-testimonials .case-study-cat li:nth-child(6) {
  display: none;
}

.blog-posts-list-view .archive-testimonial {
  height: fit-content;
  margin: 0 0 25px;
}

.ex-name-onlinebrochure .hero-banner {
  background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-hero-bg-alt.jpg") !important;
  background-color: red !important;
}
.ex-name-onlinebrochure .form-group.address {
  grid-column: 2/span 2;
}
@media only screen and (max-width: 768px) {
  .ex-name-onlinebrochure .form-group.address {
    grid-column: 1/span 2;
  }
}
@media only screen and (max-width: 481px) {
  .ex-name-onlinebrochure .form-group.address {
    grid-column: 1;
  }
}
@media only screen and (max-width: 768px) {
  .ex-name-onlinebrochure .form-section {
    grid-template-columns: 1fr 1fr;
  }
  .ex-name-onlinebrochure .form-section .form-group {
    padding: 0 5px 25px;
  }
}
@media only screen and (max-width: 481px) {
  .ex-name-onlinebrochure .form-section {
    grid-template-columns: 1fr;
  }
}

.link-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 75px 30px;
}
@media only screen and (max-width: 991px) {
  .link-grid {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
}
@media only screen and (max-width: 481px) {
  .link-grid {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}
.link-grid .link-card .image-link {
  display: block;
  height: 327px;
  border-radius: 9px;
  margin: 0 0 35px;
  overflow: hidden;
}
.link-grid .link-card .image-link img {
  height: 327px;
  width: 327px;
  border-radius: 9px;
  object-fit: cover;
}
.link-grid .link-card .image-link:hover img {
  animation: broc-anim 2s ease forwards;
}
@keyframes broc-anim {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.8);
    filter: blur(2px);
  }
  100% {
    transform: scale(1.2);
  }
}
@media only screen and (max-width: 481px) {
  .link-grid .link-card .image-link {
    height: 150px !important;
    margin: 0 0 10px;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    transition: box-shadow 0.15s, transform 0.15s;
  }
  .link-grid .link-card .image-link:focus {
    box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  }
  .link-grid .link-card .image-link:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  .link-grid .link-card .image-link:active {
    box-shadow: #d6d6e7 0 3px 7px inset;
    transform: translateY(2px);
  }
  .link-grid .link-card .image-link img {
    animation: none !important;
  }
}
.link-grid .link-card h2 {
  margin: 0 0 20px;
  font-size: 1.25em;
}
@media only screen and (max-width: 481px) {
  .link-grid .link-card h2 {
    text-align: center;
  }
}
.link-grid .link-card .tile-readmore {
  transition: 0.35s ease;
}
@media only screen and (max-width: 481px) {
  .link-grid .link-card .tile-readmore {
    display: none;
  }
}