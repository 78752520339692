// **** Hero Banner Section ****//
.hero {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin-bottom: 0 !important;

  // *** Styling dots and play button *** //
  @media only screen and (max-width: 991px) {
    .slick-autoplay-toggle-button {
      display: none;
    }

    .slick-dots {
      display: none !important;
    }
  }

  .slick-autoplay-toggle-button {
    bottom: 11px;
  }

  .slick-dots {
    bottom: 15px;

    li {
      left: -20px;
      margin: 0 1px;

      .slick-dot-icon {
        color: #797573;
        opacity: 1;

        &:hover {
          color: $col-primary;
        }

        &::before {
          margin-top: 0;
          margin-left: 0;
          font-size: 14px;
        }
      }

      &.slick-active .slick-dot-icon {
        color: #181617;

        &::before {
          margin-top: 0;
          margin-left: 0;
          font-size: 14px;
        }
      }
    }
  }

  .text-flex {
    position: relative;
    width: 770px;
    height: 540px;
    @include container;

    @media only screen and (max-width: 991px) {
      width: 100%;
      height: 340px;
    }

    @media only screen and (max-width: 768px) {
      height: 100%;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 500%;
        top: 100%;
        left: 0;
        background-color: rgba(255 255 255 / 83%);
        backdrop-filter: blur(8px);
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .slick-track {
      display: flex;
    }

    .slick-slide {
      height: inherit !important;
    }
  }

  // *** Styling dots and play button END*** //

  // * Hero text styling * //
  .hero-text {
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    gap: 10px;
    width: 420px;
    height: 100%;
    background-color: rgba(255 255 255 / 83%);
    backdrop-filter: blur(8px);

    &::before {
      content: "";
      position: absolute;
      width: 500%;
      height: 100%;
      right: 100%;
      background-color: rgba(255 255 255 / 83%);
      backdrop-filter: blur(8px);
    }

    &::after {
      content: "";
      position: absolute;
      width: 110px;
      height: 100%;
      left: 100%;
      background-color: rgba(255 255 255 / 83%);
      backdrop-filter: blur(8px);
    }

    @media only screen and (max-width: 991px) {
      width: 100%;
      padding: 35px 10%;
    }

    // * Hero text styling * //

    // * Hero animation * //

    .welcome {
      transform: skewX(30deg) rotateX(90deg);
      font-size: 1.5em;
      color: $col-primary;
    }

    h1 {
      transform: skewX(30deg) rotateX(90deg);
      line-height: 50px;
      color: $col-text;
    }

    p {
      transform: skewX(30deg) rotateX(90deg);
      font-size: 1.125em;
      line-height: 26px;
      font-weight: 500;
      margin-bottom: 20px;
      color: $col-text;
    }

    a {
      transform: rotateY(90deg);
    }
  }

  .slick-active .hero-text {
    .welcome {
      transform: skewX(0deg) rotateX(0deg);
      transition: transform 0.5s ease-in 0s;
    }

    h1 {
      transform: skewX(0deg) rotateX(0deg);
      transition: transform 0.5s ease-in 0.1s;
    }

    p {
      transform: skewX(0deg) rotateX(0deg);
      transition: transform 0.5s ease-in 0.2s;
    }

    a {
      transform: rotateY(0deg);
      transition: transform 0.35s ease-in 0.3s;
    }
  }

  img {
    object-fit: cover;
    @include coverarea;
  }

  // * Hero animation END * //
}

// **** Hero Banner Section End ****//
