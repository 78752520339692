.ex-name-testimonials {
  .category-name {
    display: none;
  }

  .itemsfound {
    display: none !important;
  }

  .listgrid {
    display: block;
    position: absolute;
    right: 20px;
    top: 50px;
  }

  .case-study-cat {
    margin: 0 0 50px;

    li:nth-child(1),
    li:nth-child(4),
    li:nth-child(5),
    li:nth-child(6) {
      display: none;
    }
  }
}

.blog-posts-list-view {
  .archive-testimonial {
    height: fit-content;
    margin: 0 0 25px;
  }
}
