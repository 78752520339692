// ********** GLOBAL ********** //

// *** Title Decoration *** //

.title-decoration {
  margin: 0 0 40px;
  font-size: var(--evo-font-size-h2);
  @include flex(center);

  span {
    width: 7px;
    height: 7px;
    display: block;
    background-color: $col-primary;
    border-radius: 50%;
    flex-shrink: 0;

    &:first-child {
      margin-right: 20px;
    }

    &:last-child {
      margin-left: 20px;
    }
  }

  &::before,
  &::after {
    content: "";
    width: 110px;
    height: 1px;
    display: block;
    background-color: $col-primary;
  }
}

// *** End Title Decoration *** //

// ********** END GLOBAL ********** //
