// @charset "UTF-8";

@import "../../../node_modules/accessible-slick/slick/slick";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"
// "\23f8" outputs ascii character "⏸"
// "\25b6" outputs ascii character "▶"

$slick-font-path: "./fonts/";
$slick-font-family: fontawesome;
$slick-loader-path: "./";
$slick-arrow-color: $col-primary;
$slick-dot-color: $col-primary;
$slick-dot-color-active: $col-secondary;
$slick-prev-character: "\f053";
$slick-next-character: "\f054";
$slick-dot-character: "\f111";
$slick-pause-character: "\f04c";
$slick-play-character: "\f04b";
$slick-dot-size: 12px;
$slick-opacity-default: 1;
$slick-opacity-on-hover: 0.75;
$slick-opacity-active: 1;
$slick-opacity-not-active: 0.25;
$slick-arrow-font: 1.25rem;

@import "../../../node_modules/accessible-slick/slick/accessible-slick-theme";

.slick-slider {
  padding: 0;
}

.slick-arrow {
  z-index: 1;
  background-color: $col-text;
  width: 25px;
  height: 55px;
  top: 20px;
  @include flex(center);

  &:hover,
  &:focus-visible {
    background-color: $col-primary;
  }
}

.slick-prev,
.slick-next {
  top: calc(50% - 12px);

  .slick-prev-icon,
  .slick-next-icon {
    font-size: $slick-arrow-font;

    &::before {
      content: "";
      background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-right-arrow.svg");
      background-size: contain;
      width: 9px;
      height: 14px;
      display: block;
      filter: brightness(0) invert(1);
    }
  }

  &:hover,
  &:focus {
    top: calc(50% - 12px);

    .slick-prev-icon,
    .slick-next-icon {
      color: $col-secondary;
      margin: 0;
      font-size: $slick-arrow-font;
    }
  }
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

.slick-prev-icon {
  &::before {
    transform: rotate(180deg);
  }
}

.slick-autoplay-toggle-button {
  z-index: 1;

  &:hover {
    opacity: $slick-opacity-on-hover;
  }

  &:focus {
    color: $col-secondary;
  }
}
