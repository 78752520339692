// ********** MODULE - LATEST PROJECTS & NEWS ********** //

.module-projects-news {
  .container {
    > .nFlex {
      align-items: stretch;
    }
  }
}

// *** Post Tiles *** //

.module-projects {
  width: 49%;

  .slick-arrow {
    top: 583px;
  }
}

.module-news {
  width: 49%;
  max-height: 800px;
  overflow: auto;
}

.tile-blog {
  border-radius: 10px;
  overflow: hidden;

  @at-root .tile-img {
    height: 445px;
    width: 100%;
    display: block;
    position: relative;
    transition: opacity 0.35s;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @at-root .tile-category {
      position: absolute;
      width: 100%;
      height: 50%;
      bottom: 0;
      left: 0;
      padding: 15px 22px;
      background: linear-gradient(0deg, rgb(0 0 0 / 83%) 0%, rgb(0 0 0 / 0%) 100%);
      @include flex(flex-start, flex-end);

      img {
        margin-right: 15px;
      }

      span {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 0.875em;
        color: #fff;
      }
    }

    &:hover,
    &:focus-visible {
      opacity: 0.7;
    }
  }

  @at-root .tile-text {
    background-color: #fff;
    padding: 40px;
    height: 360px;
    overflow: auto;

    @at-root .tile-title {
      margin: 0 0 15px;
    }

    @at-root .tile-excerpt {
      p {
        margin: 0 0 15px;
        line-height: 1.6;
      }
    }

    @at-root .tile-readmore {
      display: inline-block;
      font-weight: 700;

      img {
        filter: $filter-primary;
        margin-left: 10px;
        display: inline-block;
      }

      &:hover,
      &:focus-visible {
        text-decoration: underline;
      }
    }
  }
}

// * Grid View * //

.tile-grid {
  .tile-text {
    p {
      &:first-child {
        font-weight: 500;
      }
    }
  }

  .tile-title {
    font-size: 1.25em;
  }
}

// * End Grid View * //

// * List View * //
.tile-list {
  @include flex(space-between, stretch);

  .tile-img {
    width: 42%;
    height: 300px;
  }

  .tile-text {
    padding: 25px;
    width: 58%;
    height: 300px;
    overflow: auto;
    font-size: 0.875em;
  }

  + .tile-list {
    margin-top: 20px;
  }
}

// * End List View * //

// *** End Post Tiles (Project) *** //

// ********** END MODULE - LATEST PROJECTS & NEWS ********** //
