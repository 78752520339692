// ********** MODULE - CATEGORY MENU BOXES ********** //
// * reset font size for all fonts within menu * //
section {
  .quicklinks {
    ul {
      font-size: 1em;
    }
  }
}

.cat-menu-boxes {
  .hasSubmenus {
    padding-bottom: 0;

    // * accordian for tablet and mobile view * //
    @media only screen and (max-width: 768px) {
      .has-submenu {
        height: 100px;
        transition: all 0.35s;

        &::after {
          content: "+";
          position: absolute;
          left: calc(50% - 8px);
          top: 65px;
          z-index: 1;
          isolation: isolate;
          font-size: 25px;
          color: $col-primary;
          transform: rotate(0deg);
          transition: all 0.35s;
        }

        &.nActive {
          height: 380px;

          img {
            opacity: 0.03;
          }

          &::after {
            transform: rotate(135deg);
          }
        }
      }
    }

    // * accordian for tablet and mobile view END * //

    .has-submenu {
      margin-bottom: 0;

      .submenu {
        isolation: isolate;
        z-index: -1;
      }

      // *** hover effects for screens larger than 769px *** //
      @media only screen and (min-width: 769px) {
        &:hover,
        &:focus-within {
          > img {
            opacity: 0.02;
          }

          .submenu {
            margin-top: -25px;

            .cat-box-icon {
              opacity: 0;
            }

            li {
              &:nth-child(2) {
                &::after {
                  margin-bottom: 6px;
                }
              }

              &:nth-child(4),
              &:nth-child(5),
              &:nth-child(6),
              &:nth-child(7),
              &:nth-child(8) {
                margin-bottom: 6px;
              }
            }
          }
        }
      }

      // *** hover effects for screens larger than 769px END *** //
    }
  }

  // *** Styling on the tiles and responsive grid *** //

  > ul {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1px;
    overflow: hidden;

    @media only screen and (max-width: 1280px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr;
    }

    > li {
      position: relative;
      height: 300px;
      background-color: $col-black;
      flex-basis: 16.5%;

      > img {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.15;
        transition: opacity 0.35s;
        object-fit: cover;
      }
    }
  }

  // *** Styling on the tiles and responsive grid END*** //

  // *** Styling the text and icons in tiles *** //
  .submenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-left: 0 !important;
    align-items: center;
    margin-top: 90px;
    transition: 0.35s;

    .cat-box-icon {
      display: flex;
      justify-content: center;
      opacity: 1;
      transition: 0.35s;

      a {
        height: 35px;
        width: 35px;
        filter: brightness(0) invert(100%);
      }
    }

    li {
      a {
        color: #fff;
        font-size: 1em;

        &:hover,
        &:focus-within {
          text-decoration: underline;
        }
      }

      &:nth-child(2) {
        a {
          position: relative;
          width: 160px;
          font-weight: 700;
          font-size: 1.375em;
          line-height: normal;
          color: #fff;
        }

        &::after {
          content: "";
          display: block;
          margin-inline: auto;
          width: 53px;
          height: 3px;
          background-color: $col-primary;
          margin-bottom: 70px;
          transition: 0.35s;
        }
      }

      // *** lazy transition effect *** //

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        margin-bottom: 30px;
      }

      &:nth-child(4) {
        transition: 0.35s ease-in-out 0.1s;
      }

      &:nth-child(5) {
        transition: 0.35s ease-in-out 0.2s;
      }

      &:nth-child(6) {
        transition: 0.35s ease-in-out 0.3s;
      }

      &:nth-child(7) {
        transition: 0.35s ease-in-out 0.4s;
      }

      &:nth-child(8) {
        transition: 0.35s ease-in-out 0.5s;
      }

      // *** lazy transition effect End *** //

      &:last-child {
        a {
          font-weight: 600;
          color: $col-primary;
        }
      }
    }

    // * text and font sizes on mobile * //
    @media only screen and (max-width: 768px) {
      margin-top: 15px;

      li {
        &:first-child {
          display: none;
        }

        &:nth-child(2) {
          width: max-content;

          a {
            width: 100%;
            margin-top: 10px;
            margin-bottom: 50px;
            font-size: 1em;
          }

          &::after {
            display: none;
          }
        }
      }
    }

    // * text and font sizes on mobile END * //
  }

  // *** Styling the text and icons in tiles End *** //
}

// ********** END MODULE - CATEGORY MENU BOXES ********** //
