.icon-list {
  margin: 50px 0;

  &::before {
    display: none;
  }

  ul {
    flex-direction: row;
    margin: 0;
    padding: 0;

    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
    }

    li {
      gap: 15px;
      list-style: none;
      margin: 0;

      svg {
        display: inline-block;
        height: 50px;
        stroke: $col-primary;
      }

      p {
        color: $col-text;
        margin: 0;
      }
    }
  }
}

.post-content {
  p:first-of-type {
    font-weight: 600;
  }
}

.widget-blogs-search {
  .searchlist {
    margin: 50px 0;

    h4 {
      margin: 0 0 15px;
    }

    .relsearch {
      .form-control {
        height: 55px;
        border-radius: 27.5px;
        border: 1px solid $col-tertiary;
        padding: 25px 20px;
        margin: 0;
      }
    }
  }
}

.blogsearch_container .search-form button[type="submit"] {
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 55px;
  width: 55px;

  i {
    background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-icon-search.svg");
    height: 15px;
    width: 15px;
    background-size: contain;

    &::before {
      display: none;
    }
  }

  &:hover {
    opacity: 0.4;
  }
}

.case-study-cat {
  h4 {
    font-weight: 700;
    margin: 0 0 15px;
  }

  ul {
    padding: 0;
  }

  li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
    padding: 10px 0;
    margin: 0;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      display: block;
      height: 1px;
      width: 100%;
      background: #dedede;
    }

    &:last-of-type {
      margin: 0 0 40px;

      &::after {
        display: none;
      }
    }

    a {
      color: $col-text;

      &:hover {
        color: $col-primary;
      }
    }

    p {
      margin: 0;

      &::before {
        content: "(";
      }

      &::after {
        content: ")";
      }
    }
  }
}

.case-study-title {
  font-weight: 700;
  margin: 0 0 15px;
}

.blog-posts-list-view .blog-post-wrapper {
  .tile {
    padding: 0;
    margin: 0 0 15px;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 991px) {
      max-width: fit-content;
    }

    a {
      transition: 0.35s ease;

      &:hover {
        opacity: 0.4;
      }
    }

    .post-image {
      width: 128px;
      height: 128px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 9px;
    }

    .footer {
      width: 55%;
      min-height: auto;

      @media only screen and (max-width: 991px) {
        width: 100%;
      }

      .footer-wrapper {
        min-height: auto;

        .post-excerpt {
          max-height: 120px;
          margin: 0;
          overflow-y: auto;
          @include em(14);
        }
      }
    }
  }
}
