// ** changes to widgets
.ex-name-onlinebrochure {
  .hero-banner {
    background-image: url("https://eu.evocdn.io/dealer/1748/content/media/My_Theme/an-hero-bg-alt.jpg") !important;
    background-color: red !important;
  }

  .form-group.address {
    grid-column: 2/span 2;

    @media only screen and (max-width: 768px) {
      grid-column: 1/span 2;
    }

    @media only screen and (max-width: 481px) {
      grid-column: 1;
    }
  }

  @media only screen and (max-width: 768px) {
    .form-section {
      grid-template-columns: 1fr 1fr;

      .form-group {
        padding: 0 5px 25px;
      }
    }
  }

  @media only screen and (max-width: 481px) {
    .form-section {
      grid-template-columns: 1fr;
    }
  }
}

// ** brochure grid layout
.link-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 75px 30px;

  @media only screen and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  @media only screen and (max-width: 481px) {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .link-card {
    .image-link {
      display: block;
      height: 327px;
      border-radius: 9px;
      margin: 0 0 35px;
      overflow: hidden;

      img {
        height: 327px;
        width: 327px;
        border-radius: 9px;
        object-fit: cover;
      }

      &:hover {
        img {
          animation: broc-anim 2s ease forwards;
        }

        @keyframes broc-anim {
          0% {
            transform: scale(1);
          }

          30% {
            transform: scale(0.8);
            filter: blur(2px);
          }

          100% {
            transform: scale(1.2);
          }
        }
      }

      @media only screen and (max-width: 481px) {
        height: 150px !important;
        margin: 0 0 10px;
        box-shadow:
          rgb(45 35 66 / 40%) 0 2px 4px,
          rgb(45 35 66 / 30%) 0 7px 13px -3px,
          #d6d6e7 0 -3px 0 inset;
        transition: box-shadow 0.15s, transform 0.15s;

        &:focus {
          box-shadow:
            #d6d6e7 0 0 0 1.5px inset,
            rgb(45 35 66 / 40%) 0 2px 4px,
            rgb(45 35 66 / 30%) 0 7px 13px -3px,
            #d6d6e7 0 -3px 0 inset;
        }

        &:hover {
          box-shadow:
            rgb(45 35 66 / 40%) 0 4px 8px,
            rgb(45 35 66 / 30%) 0 7px 13px -3px,
            #d6d6e7 0 -3px 0 inset;
          transform: translateY(-2px);
        }

        &:active {
          box-shadow: #d6d6e7 0 3px 7px inset;
          transform: translateY(2px);
        }

        img {
          animation: none !important;
        }
      }
    }

    h2 {
      margin: 0 0 20px;
      @include em(20);

      @media only screen and (max-width: 481px) {
        text-align: center;
      }
    }

    .tile-readmore {
      transition: 0.35s ease;

      @media only screen and (max-width: 481px) {
        display: none;
      }
    }
  }
}
